import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './DirectSMS.css';

const DirectSMS = () => {
  const [selectedChannel, setSelectedChannel] = useState('');
  const [fromNumber, setFromNumber] = useState('');
  const [recipients, setRecipients] = useState('');
  const [messageType, setMessageType] = useState('Plain');
  const [message, setMessage] = useState('');
  const [countryCode, setCountryCode] = useState('+234');

  const maxCharacters = 160;
  const charactersRemaining = maxCharacters - message.length;

  const handleSend = async () => {
    if (!fromNumber || !recipients || !message) {
      toast.error('Please fill in all required fields');
      return;
    }

    const formattedRecipients = recipients
      .split(',')
      .map((recipient) => countryCode + recipient.trim())
      .filter((recipient) => recipient);

    const data = {
      user_id: 54, 
      body: message,
      to: formattedRecipients,
      from: [fromNumber],
      unicode: false,
      type: "SMS"
    };

    try {
      const response = await fetch('http://127.0.0.1:5000/api/sms/send_sms', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (response.ok) {
        toast.success('SMS sent successfully');
        setSelectedChannel('');
        setFromNumber('');
        setRecipients('');
        setMessage('');
        setMessageType('Plain');
        setCountryCode('+234');
      } else {
        toast.error(`Failed to send SMS: ${result.message || 'Unknown error'}`);
      }
    } catch (error) {
      toast.error('Failed to send SMS');
    }
  };

  const handleViewReport = () => {
    console.log('Viewing report');
  };

  const calculateRecipientCount = () => {
    return recipients
      .split(',')
      .map((recipient) => recipient.trim())
      .filter((recipient) => recipient).length;
  };

  const countryCodes = [
    { code: '+1', name: 'USA' },
    { code: '+234', name: 'Nigeria' },
    { code: '+44', name: 'UK' },
    { code: '+91', name: 'India' },
  ];

  return (
    <div className="direct-sms-container">
      <ToastContainer />
      <div className="container">
        <h2 className="text-left">Direct SMS</h2>

        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="channel">Select Channel</label>
            <select
              id="channel"
              className="form-control"
              value={selectedChannel}
              onChange={(e) => setSelectedChannel(e.target.value)}
            >
              <option value="">Select SMS channel</option>
              <option value="channel1">Channel 1</option>
              <option value="channel2">Channel 2</option>
            </select>
          </div>

          <div className="col-md-6">
            <label htmlFor="senderID">Sender ID / Device ID</label>
            <select
              id="senderID"
              className="form-control"
              // value={selectedSenderID}
              // onChange={(e) => setSelectedSenderID(e.target.value)}
            >
              <option value="">Select Sender ID</option>
              <option value="device1">Device 1</option>
              <option value="device2">Device 2</option>
            </select>
            <p className="text-primary-custom mt-2">
                Can't find your ID?{' '}
                <a href="#" className="link-custom">
                    Register your ID here
                </a>. Process takes less than 10 mins.
            </p>

          </div>

          <div className="col-md-6">
            <label htmlFor="fromNumber">From Number</label>
            <input
              type="text"
              id="fromNumber"
              className="form-control"
              value={fromNumber}
              onChange={(e) => setFromNumber(e.target.value)}
              placeholder="Enter From Number"
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="countryCode">Country Code</label>
            <select
              id="countryCode"
              className="form-control"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
            >
              <option value="">Select country code</option>
              {countryCodes.map(({ code, name }) => (
                <option key={code} value={code}>
                  {code} ({name})
                </option>
              ))}
            </select>
            <textarea
              className="form-control mt-2"
              value={recipients}
              onChange={(e) => setRecipients(e.target.value)}
              placeholder="Separate the numbers using a comma e.g. 08012345678,09087654321"
              rows="4"
            />
            <p className="mt-2">Total Number Of Recipients: {calculateRecipientCount()}</p>
          </div>

          <div className="col-md-6">
            <label htmlFor="messageType">Message Type</label>
            <select
              id="messageType"
              className="form-control"
              value={messageType}
              onChange={(e) => setMessageType(e.target.value)}
            >
              <option value="Plain">Plain</option>
              <option value="Unicode">Unicode</option>
            </select>
            <textarea
              id="message"
              className="form-control mt-2"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
              rows="4"
              maxLength={maxCharacters}
            />
            <p className="text-right mt-1">
              {charactersRemaining} characters remaining ({Math.ceil(message.length / maxCharacters)} message)
            </p>
          </div>
        </div>

        <div style={{ margin: '10px' }}>
          <button
            onClick={handleSend}
            style={{
              backgroundColor: '#39B7E9',
              color: 'white',
              border: 'none',
              padding: '10px 30px',
              borderRadius: '4px',
              cursor: 'pointer',
              marginRight: '20px'
            }}
          >
            Send
          </button>
          <button
            onClick={handleViewReport}
            style={{
              backgroundColor: '#E3F5FC',
              color: '#39B7E9',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            View report
          </button>
        </div>
      </div>
    </div>
  );
};

export default DirectSMS;
