import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CallLogsComponent = () => {
    const [callLogs, setCallLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCallLogs = async () => {
            try {
                const response = await axios.get('http://127.0.0.1:5000/api/click_to_call/get_call_logs_by_user/54');
                const data = response.data;

                if (data.status === 1) {
                    setCallLogs(data.data);
                } else {
                    setError(data.message || 'Failed to fetch call logs');
                }
            } catch (error) {
                setError('An error occurred while fetching call logs');
            } finally {
                setLoading(false);
            }
        };

        fetchCallLogs();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p className="text-danger">{error}</p>;

    return (
        <div className="container mt-5">
            <h1 className="mb-4">Call Logs</h1>
            {callLogs.length > 0 ? (
                <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                        <thead className="table-light">
                            <tr>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Source Number</th>
                                <th>Destination Number</th>
                                <th>IVR ID</th>
                                <th>Status</th>
                                <th>Status Code</th>
                                <th>Success Message</th>
                                <th>Error Message</th>
                                <th>Unique ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {callLogs.map((log) => (
                                <tr key={log.id}>
                                    <td>{log.id}</td>
                                    <td>{log.date}</td>
                                    <td>{log.source_number}</td>
                                    <td>{log.destination_number}</td>
                                    <td>{log.ivr_id}</td>
                                    <td>{log.status}</td>
                                    <td>{log.status_code}</td>
                                    <td>{log.success_msg || 'N/A'}</td>
                                    <td>{log.error_message || 'N/A'}</td>
                                    <td>{log.uniqueid}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p>No call logs available</p>
            )}
        </div>
    );
};

export default CallLogsComponent;
