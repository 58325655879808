import React from 'react';
import { Helmet } from 'react-helmet';

const KYC = () => {
    return (
        <div>
            <Helmet>
                <title>KYC - Know Your Customer</title>
                <meta name="description" content="Complete your KYC (Know Your Customer) process to ensure secure transactions." />
                <meta name="keywords" content="KYC, Know Your Customer, compliance, security" />
            </Helmet>
            <h3>KYC</h3>
            <p>KYC details go here.</p>
        </div>
    );
};

export default KYC;
