import {React, useEffect} from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import NotFound from '../../pages/NotFound/NotFound';
import Navbar from '../Navbar/Navbar';
import Sidebar from '../Sidebar/Sidebar';
import Dashboard from '../../pages/Dashboard/Dashboard';
import './DefaultLayout.css';
import TopUp from '../../pages/TopUp/TopUp';
import Invoice from '../../pages/Invoice/Invoice';
import Profile from '../../pages/Profile/Profile';
import ChangePassword from '../../pages/ChangePassword/ChangePassword';
import MainComponents from '../../pages/MainComponents/MainComponents';
import ApiToken from '../../pages/ApiToken/ApiToken';
import WebhookConfig from '../../pages/WebhookConfig/WebhookConfig';
import DeactivateAccount from '../../pages/DeactivateAccount/DeactivateAccount';
import EmailNotification from '../../pages/EmailNotification/EmailNotification';
import KYC from '../../pages/KYC/KYC';
import Developers from '../../pages/Developers/Developers';
import MessageList from '../../pages/Message/MessageList';
import Tokens from '../../pages/Tokens/Tokens';
import Insight from '../../pages/Insight/Insight';
import MessagingReport from '../../pages/MessagingReport/MessagingReport';
import NumberAPI from '../../pages/NumberAPI/NumberAPI';
import DND from '../../pages/DND/DND';
import Generic from '../../pages/Generic/Generic';
import Settings from '../../pages/Settings/Settings';
import DetailedTransactionHistory from '../../pages/DetailedTransactionHistory/DetailedTransactionHistory';
import Numbers from '../../pages/Numbers/Numbers';                
import Contacts from '../../pages/Contacts/Contacts';             
import DirectSMS from '../../pages/DirectSMS/DirectSMS';
import GroupSMS from '../../pages/GroupSMS/GroupSMS';
import MessagingInsight from '../../pages/MessagingInsight/MessagingInsight';
import AddContactForm from '../../pages/AddContactForm/AddContactForm';
import CallInitiator from '../../pages/initiateCall/initiateCall';
import ReportComponent from '../../pages/ReportComponent/ReportComponent';
import CallLogsComponent from '../../pages/CallLogsComponent/CallLogsComponent';

const DefaultLayout = () => {

    useEffect(() => {
        // Store the referrer URL in localStorage when the component is loaded
        if (document.referrer) {
            localStorage.setItem('referrerUrl', document.referrer);
        }
    }, []);

    return (
        <div className="default-layout">
        <div className="content">
            <Sidebar />
            <main role="main" className="main-content">
                <Navbar />
                <Routes>
                    <Route path="/" element={<Navigate to="/dashboard" />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/developers" element={<Developers />} />
                    <Route path="/messages" element={<MessageList />} />
                    <Route path="/tokens" element={<Tokens />} />
                    <Route path="/detailed-transaction-history" element={<DetailedTransactionHistory />} />
                    <Route path="/add-contact" element={<AddContactForm />} />

                    <Route path="/settings" element={<Settings />} />
                    <Route path="/wallet/top-up" element={<TopUp />} />
                    <Route path="/wallet/invoice" element={<Invoice />} />
                    <Route path="/switch/send-messages" element={<DirectSMS />} />
                    <Route path="/switch/sms-sender-ids" element={<GroupSMS />} />   
                    <Route path="/switch/numbers" element={<Numbers />} />               
                    <Route path="/switch/contacts" element={<Contacts />} /> 
                    <Route path="/switch/initiate-call" element={<CallInitiator />} />
                    <Route path="/switch/reports" element={<ReportComponent />} />
                    <Route path="/switch/call-logs" element={<CallLogsComponent />} />

                    <Route path="/insight" element={<Insight />}>
                        <Route path='' element={<Navigate to="messaging-report"/> } />
                        <Route path="messaging-report" element={<MessagingReport />} />
                        <Route path="number-api" element={<NumberAPI />} />
                        <Route path="dnd" element={<DND />} />
                        <Route path="generic" element={<Generic />} />
                        <Route path="view" element={<MessagingInsight />} />
                    </Route>

                    <Route path="/main/*" element={<MainComponents />}>
                        <Route path="" element={<Navigate to="profile" />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="api-token" element={<ApiToken />} />
                        <Route path="webhook-config" element={<WebhookConfig />} />
                        <Route path="deactivate-account" element={<DeactivateAccount />} />
                        <Route path="change-password" element={<ChangePassword />} />
                        <Route path="email-notification" element={<EmailNotification />} />
                        <Route path="kyc" element={<KYC />} />
                    </Route>

                    <Route path="/facebook/success" element={<MessageList />} /> {/* Add this line */}

                    <Route path="*" element={<NotFound />} />
                </Routes>
            </main>
        </div>
    </div>
    );
};

export default DefaultLayout;
