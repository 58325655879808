import React, { useState } from 'react';
import axios from 'axios';
import './initiateCall.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CallInitiator = () => {
  const [sourceNumber, setSourceNumber] = useState('');
  const [destinationNumber, setDestinationNumber] = useState('');
  const [ivrId, setIvrId] = useState('');
  const [loading, setLoading] = useState(false);

  const userId = 54;
  const tokenKey = 'x7pySCAG7GUfkWMS';

  const initiateCall = async () => {
    setLoading(true);
    try {
      const response = await axios.post('http://127.0.0.1:5000/api/click_to_call/initiate_call', {
        source_number: sourceNumber,
        destination_number: destinationNumber,
        ivr_id: ivrId,
        token_key: tokenKey,
        user_id: userId,
      });

      toast.success(`Success: ${response.data.message}`);
    } catch (error) {
      console.log(error.response)
      if (error.response) {
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        toast.error('An error occurred while initiating the call.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="call-initiator-container mt-5">
      <h2 className="title">Initiate a Call</h2>
      <div className="form-group">
        <label htmlFor="sourceNumber">Source Number:</label>
        <input
          id="sourceNumber"
          type="text"
          value={sourceNumber}
          onChange={(e) => setSourceNumber(e.target.value)}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label htmlFor="destinationNumber">Destination Number:</label>
        <input
          id="destinationNumber"
          type="text"
          value={destinationNumber}
          onChange={(e) => setDestinationNumber(e.target.value)}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label htmlFor="ivrId">IVR ID:</label>
        <input
          id="ivrId"
          type="text"
          value={ivrId}
          onChange={(e) => setIvrId(e.target.value)}
          className="input-field"
        />
      </div>
      <button 
        onClick={initiateCall} 
        disabled={loading} 
        className={`submit-button ${loading ? 'loading' : ''}`}
      >
        {loading ? 'Initiating Call...' : 'Initiate Call'}
      </button>
      <ToastContainer />
    </div>
  );
};

export default CallInitiator;
