import React from 'react';
import './PasswordReset.css';
import { Helmet } from 'react-helmet';

const PasswordReset = () => {
    return (
        <>
            <Helmet>
                <title>Password Reset</title>
            </Helmet>

         <div className="password-reset-container d-flex vh-100">
            <div className="password-reset-left d-flex flex-column justify-content-center align-items-center bg-white p-4">
                <div className="password-reset-form w-100" style={{ maxWidth: '400px' }}>
                    <h2 className="text-start">Password Reset</h2>
                    <p className="text-start">We've sent a link to your email to reset your password. The link is valid for 24 hours only.</p>
                    <button className="btn btn-primary w-100 mb-3">Update password</button>
                </div>
            </div>
            <div className="signup-right">
                <img src={'../assets/top-image.png'} alt="top-right-image" className="top-right-image" />
                <img src={'../assets/Group.png'} alt="Logo" className="logo" />
                <img src={'../assets/button-image.png'} alt="bottom-left-image" className="bottom-left-image" />
            </div>
        </div>
        </>
        
    );
};

export default PasswordReset;
