import React from 'react';
import { Helmet } from 'react-helmet';

const Settings = () => {
    return (
        <div>
            <Helmet>
                <title>Settings | Your App Name</title>
                <meta name="description" content="Adjust your application settings here." />
            </Helmet>
            <h1>Settings</h1>
            <p>Adjust your application settings here.</p>
        </div>
    );
};

export default Settings;
