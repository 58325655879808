import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './MainComponent.css';

const MainComponents = () => {
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname.includes(path);
    };

    const routeInfo = {
        '/main/profile': {
            header: 'Your Account Profile',
            description: 'We have given you the power to integrate your application with our system.'
        },
        '/main/api-token': {
            header: 'Your API Keys',
            description: 'Manage your API tokens for accessing the application programmatically.'
        },
        '/main/webhook-config': {
            header: 'Webhook Config',
            description: 'Configure your webhook settings for receiving real-time notifications.'
        },
        '/main/deactivate-account': {
            header: 'Deactivate Account',
            description: 'Deactivate your account if you no longer wish to use our services.'
        },
        '/main/change-password': {
            header: 'Change Your Password',
            description: 'We have given you the power to set security access to your dashboard. Change your Nativetalk password using the form below.'
        },
        '/main/email-notification': {
            header: 'Email Notification',
            description: 'Manage your email notification preferences.'
        },
        '/main/kyc': {
            header: 'KYC',
            description: 'Complete your Know Your Customer verification process.'
        }
    };

    const currentPath = Object.keys(routeInfo).find((path) => location.pathname.includes(path)) || '/main/change-password';
    const { header, description } = routeInfo[currentPath];

    return (
        <div className="main-component">
            <h2>{header}</h2>
            <p>{description}</p>
            <div className="d-flex justify-content-between align-items-center">
                <div className="main-component-sidebar">
                    <ul>
                        <li className={isActive('profile') ? 'active' : ''}>
                            <Link to="profile">
                                My Profile
                                <FontAwesomeIcon icon={faAngleRight} className="fa-angle-right" />
                            </Link>
                        </li>
                        <li className={isActive('api-token') ? 'active' : ''}>
                            <Link to="api-token">
                                Api Token
                                <FontAwesomeIcon icon={faAngleRight} className="fa-angle-right" />
                            </Link>
                        </li>
                        <li className={isActive('webhook-config') ? 'active' : ''}>
                            <Link to="webhook-config">
                                Webhook Config
                                <FontAwesomeIcon icon={faAngleRight} className="fa-angle-right" />
                            </Link>
                        </li>
                        <li className={isActive('deactivate-account') ? 'active' : ''}>
                            <Link to="deactivate-account">
                                Deactivate Account
                                <FontAwesomeIcon icon={faAngleRight} className="fa-angle-right" />
                            </Link>
                        </li>
                        <li className={isActive('change-password') ? 'active' : ''}>
                            <Link to="change-password">
                                Change Password
                                <FontAwesomeIcon icon={faAngleRight} className="fa-angle-right" />
                            </Link>
                        </li>
                        <li className={isActive('email-notification') ? 'active' : ''}>
                            <Link to="email-notification">
                                Email Notification
                                <FontAwesomeIcon icon={faAngleRight} className="fa-angle-right" />
                            </Link>
                        </li>
                        <li className={isActive('kyc') ? 'active' : ''}>
                            <Link to="kyc">
                                KYC
                                <FontAwesomeIcon icon={faAngleRight} className="fa-angle-right" />
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="main-component-content">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default MainComponents;
