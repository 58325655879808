import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './UpdatePassword.css';

const UpdatePassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsUpdated(true);
    };

    return (
        <>
            <Helmet>
                <title>SignUp</title>
            </Helmet>

            <div className="update-password-container d-flex vh-100">
            {isUpdated && <div className="modal-overlay"></div>}
            {isUpdated ? (
                <div className="password-success-modal d-flex flex-column justify-content-center align-items-center">
                    <div className="password-success-content text-center">
                        <i className="fa-regular fa-circle-check fa-3x mb-4"></i>
                        <h2>Password updated <br /> successfully</h2>
                        <button className="btn btn-primary w-100 mt-4" onClick={() => window.location.href='/sign-in'}>Return to log in</button>
                    </div>
                </div>
            ) : (
                <div className="update-password-left d-flex flex-column justify-content-center align-items-center bg-white p-4">
                    <div className="update-password-form w-100" style={{ maxWidth: '400px' }}>
                        <h2 className="text-start">Update Password</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group text-start mb-3">
                                <label htmlFor="new-password">New Password</label>
                                <div className="password-container position-relative">
                                    <input
                                        type={showNewPassword ? "text" : "password"}
                                        id="new-password"
                                        name="new-password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        className="form-control"
                                        required
                                    />
                                    <span className="password-toggle-text position-absolute" onClick={toggleNewPasswordVisibility}>
                                        {showNewPassword ? "Hide" : "Show"}
                                    </span>
                                </div>
                            </div>
                            <div className="form-group text-start mb-3">
                                <label htmlFor="confirm-password">Confirm Password</label>
                                <div className="password-container position-relative">
                                    <input
                                        type={showConfirmPassword ? "text" : "password"}
                                        id="confirm-password"
                                        name="confirm-password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className="form-control"
                                        required
                                    />
                                    <span className="password-toggle-text position-absolute" onClick={toggleConfirmPasswordVisibility}>
                                        {showConfirmPassword ? "Hide" : "Show"}
                                    </span>
                                </div>
                            </div>
                            <button type="submit" className="btn btn-primary w-100 mb-3">Update</button>
                        </form>
                        <p className="text-start"><a href="/sign-in">Back to log in</a></p>
                        <p>Don't have an account? <a href="/sign-up">Sign up</a></p>
                    </div>
                </div>
            )}
            <div className="update-password-right">
                <img src={'../assets/top-image.png'} alt="top-right-image" className="top-right-image" />
                <img src={'../assets/Group.png'} alt="Logo" className="logo" />
                <img src={'../assets/button-image.png'} alt="bottom-left-image" className="bottom-left-image" />
            </div>
            </div>
        </>
    );
};

export default UpdatePassword;
