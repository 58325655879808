import React from 'react';
import { Helmet } from 'react-helmet';

const NotFound = () => {
    return (
        <div>
            <Helmet>
                <title>Not Found</title>
            </Helmet>
            <h1>404 - Page Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
        </div>
    );
};

export default NotFound;
