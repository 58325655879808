import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Invoice.css';
import Pagination from '../../components/Pagination/Pagination';

const invoices = [
    { id: 1, amount: '₦10,000.00', invoiceDate: '2022-08-09', dueDate: '2022-08-09', status: 'Paid', paymentType: 'Credit Card' },
    { id: 2, amount: '₦10,000.00', invoiceDate: '2022-08-09', dueDate: '2022-08-09', status: 'Due', paymentType: 'Credit Card' },
    { id: 3, amount: '₦10,000.00', invoiceDate: '2022-08-09', dueDate: '2022-08-09', status: 'Paid', paymentType: 'Credit Card' },
    { id: 4, amount: '₦10,000.00', invoiceDate: '2022-08-09', dueDate: '2022-08-09', status: 'Pending', paymentType: 'Credit Card' },
    { id: 5, amount: '₦10,000.00', invoiceDate: '2022-08-09', dueDate: '2022-08-09', status: 'Paid', paymentType: 'Credit Card' },
    { id: 6, amount: '₦10,000.00', invoiceDate: '2022-08-09', dueDate: '2022-08-09', status: 'Pending', paymentType: 'Credit Card' },
];

const Invoice = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const totalPages = Math.ceil(invoices.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentInvoices = invoices.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className='invoice'>
            <Helmet>
                <title>Invoice Management</title>
            </Helmet>
            <h2>Invoice</h2>
            <p>View, edit and pay for invoices generated on your account. All card payment types are accepted.</p>
            <div className="invoice-container">
                <table className="invoice-table">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Amount</th>
                            <th>Invoice Date</th>
                            <th>Due Date</th>
                            <th>Status</th>
                            <th>Payment Type</th>
                            <th>
                                <img src="../../assets/filter.png" alt="" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentInvoices.map((invoice, index) => (
                            <tr key={invoice.id}>
                                <td>{startIndex + index + 1}</td>
                                <td>{invoice.amount}</td>
                                <td>{invoice.invoiceDate}</td>
                                <td>{invoice.dueDate}</td>
                                <td>
                                    <span className={`status ${invoice.status === 'Paid' ? 'paid' : 'due'}`}>
                                        {invoice.status}
                                    </span>
                                </td>
                                <td>{invoice.paymentType}</td>
                                <td>
                                    <button className="invoice-actions-button">⋮</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                    startIndex={startIndex}
                    itemsPerPage={itemsPerPage}
                    totalItems={invoices.length}
                />
        </div>
    );
};

export default Invoice;
