import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import './WebhookConfig.css';

class WebhookConfig extends Component {
    state = {
        webhookUrl: '',
        error: '',
        success: ''
    };

    handleChange = (event) => {
        this.setState({ webhookUrl: event.target.value, error: '', success: '' });
    };

    handleSave = () => {
        const { webhookUrl } = this.state;

        
        const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z0-9\\-]+)\\.)+[a-z]{2,}|'+ // domain name
            'localhost|'+ // localhost
            '\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}|'+ // OR ipv4
            '\\[?[a-fA-F0-9]*:[a-fA-F0-9:]+\\]?)'+ // OR ipv6
            '(\\:\\d+)?'+ // port
            '(\\/[-a-z0-9%_.~+]*)*'+ // path
            '(\\?[;&a-z0-9%_.~+=-]*)?'+ // query string
            '(\\#[-a-z0-9_]*)?$','i'); // fragment locator

        if (!urlPattern.test(webhookUrl)) {
            this.setState({ error: 'Please enter a valid Webhook URL.' });
            return;
        }

        this.setState({ success: 'Webhook URL saved successfully!', error: '' });
        console.log('Webhook URL saved:', webhookUrl);
    };

    render() {
        const { webhookUrl, error, success } = this.state;

        return (
            <div className='webhook-config'>
                <Helmet>
                    <title>Webhook Configuration</title>
                    <meta name="description" content="Configure your webhook URL here." />
                </Helmet>
                <h3>Webhook URL</h3>
                <input 
                    type="text" 
                    placeholder='Webhook URL' 
                    value={webhookUrl}
                    onChange={this.handleChange}
                />
                <button onClick={this.handleSave}>Save</button>
                {error && <p className="error-message">{error}</p>}
                {success && <p className="webhook-config__success">{success}</p>}
            </div>
        );
    }
}

export default WebhookConfig;
