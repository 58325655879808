import React from 'react';
import './ConfirmAccount.css';
import { Helmet } from 'react-helmet';


const ConfirmAccount = () => {
    return (
        <>
            <Helmet>
                <title>Confirm Account</title>
            </Helmet>
            <div className="confirm-account-container d-flex vh-100">
                <div className="confirm-account-left d-flex flex-column justify-content-center align-items-center bg-white p-4">
                    <div className="confirm-account-form w-100" style={{ maxWidth: '400px' }}>
                        <h2 className="text-start">Confirm your account</h2>
                        <p className="text-start">We have sent a confirmation mail to your email address. This verification link is valid for 24 hours only.</p>
                        <button className="btn btn-primary w-100 mb-3">Return to homepage</button>
                    </div>
                </div>
                <div className="signup-right">
                    <img src={'../assets/top-image.png'} alt="top-right-image" className="top-right-image" />
                    <img src={'../assets/Group.png'} alt="Logo" className="logo" />
                    <img src={'../assets/button-image.png'} alt="bottom-left-image" className="bottom-left-image" />
                </div>
            </div>
        </>
       
    );
};

export default ConfirmAccount;
