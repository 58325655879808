import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './TopUp.css';

const TopUp = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [copied, setCopied] = useState(false);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); 
        });
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div className='main-topup d-flex'>
            <Helmet>
                <title>Top Up</title>
                <meta name="description" content="Top up your account and manage wallet details. View pricing and fund your wallet securely." />
            </Helmet>
            <div className="main-topup__container">
                <div className="main-topup__wallet">
                    <div className='d-flex justify-content-between align-items-center'>
                        <h1>₦3,000</h1>
                        <button>Get virtual account number</button>
                    </div>
                    <div className="main-topup__account-info">
                        <div className="main-topup__account-details-header">
                            <span>Account Number</span>
                            <span>Bank</span>
                            <span>Account Name</span>
                        </div>
                        <div className="main-topup__account-details">
                            <span>
                                2043103595
                                <span
                                    className="main-topup__copy-icon"
                                    onClick={() => copyToClipboard('2043103595')}
                                    data-tooltip={copied ? "Copied" : "Copy"}
                                >
                                    <i className="fa-sharp fa-regular fa-copy"></i>
                                </span>
                            </span>
                            <span className="main-topup__account-bank">United Bank of Africa</span>
                            <span>Nativetalk</span>
                        </div>
                    </div>
                    <button onClick={toggleModal} className='main-topup__see_full'>See full price list</button>
                </div>
                <div className="main-topup__wallet-guide">
                    <div>
                        <div className='d-flex align-items-center'>
                            <img src="../../assets/icon-park-outline_attention.png" alt="" /> <h3>Wallet Guide</h3>
                        </div>
                        <p>Minimum amount to recharge is ₦3000.</p>
                    </div>
                    <div className="main-topup__guide-details">
                        <p>
                            <img src="../../assets/Vector.png" alt="" /> <img src="../../assets/Vector.png" alt="" /> Transfer to the account number above. You need to confirm payment with your account manager on your support group before sending your proof of payment.
                        </p>
                        <p>
                            <img src="../../assets/Vector.png" alt="" /> <img src="../../assets/Vector.png" alt="" /> Add your company's name and your Temil's account email address in your payment narration for all bank transfers to Temil's bank account.
                        </p>
                        <p>
                            <img src="../../assets/Vector.png" alt="" /> <img src="../../assets/Vector.png" alt="" /> You can generate your virtual account numbers by clicking the "Get a virtual account number" button displayed on your page and your wallet topped up immediately after successful payment.
                        </p>
                        <p>
                            If you experience any issue making payment to your wallet, please reach out to your account manager or contact any of your account reps via our support channels.
                        </p>
                    </div>
                </div>
            </div>

            <div className="main-topup__form">
                <div className="main-topup__form-container">
                    <form>
                        <div className="main-topup__form-group">
                            <label htmlFor="topUpOption">Select Top Up Option</label>
                            <select id="topUpOption">
                                <option value="5000">5000</option>
                                <option value="10000">10000</option>
                                <option value="15000">15000</option>
                            </select>
                        </div>
                        <div className="main-topup__form-group">
                            <input type="number" id="amount" min="3000" />
                            <label className='main-topup__amount' htmlFor="amount">Minimum amount to recharge is ₦3000</label>
                        </div>
                        <div className="main-topup__form-group">
                            <label htmlFor="paymentMethod">Select Payment Method</label>
                            <select id="paymentMethod">
                                <option value="paystack">Pay with Paystack</option>
                                <option value="bankTransfer">Bank Transfer</option>
                            </select>
                        </div>
                        <div className="main-topup__total-amount">
                            Total: ₦3500
                        </div>
                        <div className="main-topup__notice">
                            <h4>Notice</h4>
                            <p>Also all payments would be remitted in Naira, but your accounts would be credited in your local currency.</p>
                        </div>
                        <button type="submit" className="main-topup__fund-wallet-btn">Fund wallet</button>
                    </form>
                </div>
            </div>

            {isModalOpen && (
                <div className="main-topup__modal">
                    <div className="main-topup__modal-content">
                        <span className="main-topup__modal-close" onClick={toggleModal}>&times;</span>
                        <h2>Our Price list</h2>
                        <p>See our full list of service prices</p>
                        <div className="main-topup__modal-prices">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Services</th>
                                        <th>Pricing</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>SMS</td>
                                        <td>Starting price: ₦2000</td>
                                    </tr>
                                    <tr>
                                        <td>Token</td>
                                        <td>Starting price: ₦5000</td>
                                    </tr>
                                    <tr>
                                        <td>Whatsapp</td>
                                        <td>Starting price: ₦2870</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <button className="main-topup__modal-btn">Continue to TopUp</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TopUp;
