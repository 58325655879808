import React, { useState } from 'react';
import Joi from 'joi';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './SignIn.css';

const schema = Joi.object({
    email: Joi.string().email({ tlds: { allow: false } }).required().label('Email'),
    password: Joi.string().min(8).required().label('Password'),
});

const SignIn = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState('');

    const validate = () => {
        const { error } = schema.validate(formData, { abortEarly: true });
        if (!error) return null;

        const validationErrors = {};
        for (let item of error.details) {
            validationErrors[item.path[0]] = item.message;
        }
        return validationErrors;
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validate();
        setErrors(validationErrors || {});
        if (validationErrors) return;

        try {
            const response = await axios.post('http://127.0.0.1:5000/api/users/login', formData);
            setMessage(response.data.message);
        } catch (error) {
            if (error.response) {
                setMessage(error.response.data.message);
            } else {
                setMessage('An error occurred. Please try again.');
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>SignIn</title>
            </Helmet>
            <div className="signin-container">
                <div className="signin-left">
                    <div className="signin-form">
                        <h2>Welcome Back!</h2>
                        <p>Please log in to continue</p>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">Email Address</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <div className="error-message">{errors.email}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <div className="password-container">
                                    <input 
                                        type={showPassword ? "text" : "password"} 
                                        id="password" 
                                        name="password" 
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                    <i 
                                        className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"} password-toggle-icon`} 
                                        onClick={togglePasswordVisibility}
                                    ></i>
                                </div>
                                {errors.password && <div className="error-message">{errors.password}</div>}
                            </div>
                            <div className="form-options">
                                <div>
                                    <input type="checkbox" id="stay-logged-in" />
                                    <label htmlFor="stay-logged-in">Stay logged in</label>
                                </div>
                                <Link to="/forgot-password">Forgot your password?</Link>
                            </div>
                            {message && <div className='alert alert-danger'>{message}</div>}
                            <button type="submit" className="btn btn-primary">Log in</button>
                        </form>
                        <p>Don't have an account? <Link to="/sign-up">Sign up</Link></p>
                    </div>
                </div>
                <div className="signin-right">
                    <img src={'../assets/top-image.png'} alt="top-right-image" className="top-right-image" />
                    <img src={'../assets/Group.png'} alt="Logo" className="logo" />
                    <img src={'../assets/button-image.png'} alt="buttom-left-image" className="buttom-left-image" />
                </div>
            </div>
        </>
    );
};

export default SignIn;
