import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Joi from 'joi';
import { Helmet } from 'react-helmet';
import './Profile.css';

const Profile = () => {
    const [firstName, setFirstName] = useState('Michael');
    const [lastName, setLastName] = useState('Michael');
    const [sector, setSector] = useState('Fintech');
    const [email, setEmail] = useState('contactmichaelisa@gmail.com');
    const [phone, setPhone] = useState('08107090597');
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const schema = Joi.object({
        firstName: Joi.string().required().label('First Name'),
        lastName: Joi.string().required().label('Last Name'),
        sector: Joi.string().required().label('Sector'),
        email: Joi.string().email({ tlds: { allow: false } }).required().label('Email Address'),
        phone: Joi.string().pattern(/^[0-9]+$/).required().label('Phone Number')
            .messages({ 'string.pattern.base': 'Phone Number must contain only digits' }),
    });

    const validate = () => {
        const { error } = schema.validate({ firstName, lastName, sector, email, phone }, { abortEarly: false });
        if (!error) return null;

        const validationErrors = {};
        for (let item of error.details) {
            validationErrors[item.path[0]] = item.message;
        }
        return validationErrors;
    };

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await axios.get('https://your-api-endpoint.com/get-user-data', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer YOUR_AUTH_TOKEN`
                    }
                });

                if (response.status === 200) {
                    const data = response.data;
                    setFirstName(data.firstName || firstName);
                    setLastName(data.lastName || lastName);
                    setSector(data.sector || sector);
                    setEmail(data.email || email);
                    setPhone(data.phone || phone);
                } else {
                    console.log('Failed to fetch user data');
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, []); 

    useEffect(() => {
        if (submitted) {
            const validationErrors = validate();
            setErrors(validationErrors || {});
        }
    }, [firstName, lastName, sector, email, phone, submitted]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors || {});
        setSubmitted(true);
        if (validationErrors) return;

        try {
            const response = await axios.post('https://your-api-endpoint.com/update-profile', {
                firstName,
                lastName,
                sector,
                email,
                phone,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer YOUR_AUTH_TOKEN`
                }
            });

            if (response.status === 200) {
                console.log('Profile updated successfully');
            } else {
                console.log('Failed to update profile');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    return (
        <div className="profile-container">
            <Helmet>
                <title>Profile</title>
                <meta name="description" content="Update your profile information" />
            </Helmet>
            <div className="profile-photo-section">
                <img src="../../assets/avata.png" alt="Profile" className="profile-photo" />
                <div className="photo-instructions">
                    <span>Upload your photo…</span> <br />
                    <span>Photo should be at least</span><br />
                    <span>300px by 300px</span>
                </div>
                <div className="photo-buttons">
                    <button className="change-button">Change</button>
                    <button className="remove-button">Remove</button>
                </div>
            </div>
            <div className="profile-form-section">
                <form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <div className="form-group">
                            <label>First Name</label>
                            <input
                                type="text"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            {submitted && errors.firstName && <div className="error-message">{errors.firstName}</div>}
                        </div>
                        <div className="form-group">
                            <label>Last Name</label>
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            {submitted && errors.lastName && <div className="error-message">{errors.lastName}</div>}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Sector</label>
                        <select value={sector} onChange={(e) => setSector(e.target.value)}>
                            <option value="Fintech">Fintech</option>
                            <option value="Healthcare">Healthcare</option>
                            <option value="Education">Education</option>
                        </select>
                        {submitted && errors.sector && <div className="error-message">{errors.sector}</div>}
                    </div>
                    <div className="form-group">
                        <label>Email Address</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {submitted && errors.email && <div className="error-message">{errors.email}</div>}
                    </div>
                    <div className="form-group">
                        <label>Phone Number</label>
                        <input
                            type="tel"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        {submitted && errors.phone && <div className="error-message">{errors.phone}</div>}
                    </div>
                    <button type="submit" className="save-button">Save</button>
                </form>
            </div>
        </div>
    );
};

export default Profile;
