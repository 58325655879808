import React, { useState } from 'react';
import Joi from 'joi';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './SignUp.css';

const countryCodes = [
    { code: '+1', name: 'United States' },
    { code: '+44', name: 'United Kingdom' },
    { code: '+234', name: 'Nigeria' },
    { code: '+91', name: 'India' },
];

const schema = Joi.object({
    first_name: Joi.string().required().label('First Name'),
    last_name: Joi.string().required().label('Last Name'),
    email: Joi.string().email({ tlds: { allow: false } }).required().label('Email Address'),
    password: Joi.string().min(8).required().label('Password'),
    phone_number: Joi.string().pattern(/^\+\d{1,4}\d{4,}$/).required().label('Phone Number')
        .messages({ 'string.pattern.base': 'Phone Number must contain only digits and a valid country code' }),
    company: Joi.string().required().label('Company'),
    role: Joi.string().valid('CEO', 'MANAGER', 'DEVELOPER', 'DESIGNER').required().label('Role'),
});

const SignUp = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        phone_number: '+234',
        company: '',
        role: 'CEO',
    });

    const [selectedCountryCode, setSelectedCountryCode] = useState('+234');
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});

    const validate = () => {
        const { error } = schema.validate(formData, { abortEarly: true });
        if (!error) return null;

        const validationErrors = {};
        for (let item of error.details) {
            validationErrors[item.path[0]] = item.message;
        }
        return validationErrors;
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleCountryCodeChange = (e) => {
        const code = e.target.value;
        setSelectedCountryCode(code);
        setFormData({
            ...formData,
            phone_number: code + formData.phone_number.replace(/^\+\d{1,4}/, ''),
        });
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const validationErrors = validate();
        setErrors(validationErrors || {});
        if (validationErrors) return;

        try {
            const response = await axios.post('http://127.0.0.1:5000/api/users/register', formData);
            setMessage(response.data.message);
        } catch (error) {
            if (error.response) {
                setMessage(error.response.data.message);
            } else {
                setMessage('An error occurred. Please try again.');
            }
        }
    };

    return (
        <>
            <Helmet>
                <title>SignUp</title>
            </Helmet>
            <div className="signup-container">
                <div className="signup-left">
                    <div className="signup-form">
                        <h2>Create your account</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="first_name">First Name</label>
                                    <input
                                        type="text"
                                        id="first_name"
                                        name="first_name"
                                        value={formData.first_name}
                                        onChange={handleChange}
                                    />
                                    {errors.first_name && <div className="error-message">{errors.first_name}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="last_name">Last Name</label>
                                    <input
                                        type="text"
                                        id="last_name"
                                        name="last_name"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                    />
                                    {errors.last_name && <div className="error-message">{errors.last_name}</div>}
                                </div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email Address</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <div className="error-message">{errors.email}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <div className="password-container">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                    />
                                    <i
                                        className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"} password-toggle-icon`}
                                        onClick={togglePasswordVisibility}
                                    ></i>
                                </div>
                                {errors.password && <div className="error-message">{errors.password}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone_number">Phone Number</label>
                                <div className="phone-number-container">
                                    <select
                                        id="country_code"
                                        name="country_code"
                                        value={selectedCountryCode}
                                        onChange={handleCountryCodeChange}
                                        className="country-code-dropdown"
                                    >
                                        {countryCodes.map((country) => (
                                            <option key={country.code} value={country.code}>
                                                {country.name} ({country.code})
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        type="text"
                                        id="phone_number"
                                        name="phone_number"
                                        value={formData.phone_number.replace(/^\+\d{1,4}/, '')}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                                setFormData({
                                                    ...formData,
                                                    phone_number: selectedCountryCode + value,
                                                });
                                            }
                                        }}
                                        placeholder="Enter phone number"
                                    />
                                </div>
                                {errors.phone_number && <div className="error-message">{errors.phone_number}</div>}
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label htmlFor="company">Company</label>
                                    <input
                                        type="text"
                                        id="company"
                                        name="company"
                                        value={formData.company}
                                        onChange={handleChange}
                                    />
                                    {errors.company && <div className="error-message">{errors.company}</div>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="role">Select Role</label>
                                    <select
                                        id="role"
                                        name="role"
                                        value={formData.role}
                                        onChange={handleChange}
                                    >
                                        <option value="CEO">CEO</option>
                                        <option value="MANAGER">Manager</option>
                                        <option value="DEVELOPER">Developer</option>
                                        <option value="DESIGNER">Designer</option>
                                    </select>
                                    {errors.role && <div className="error-message">{errors.role}</div>}
                                </div>
                            </div>
                            <p>By clicking create account, you agree to our <a href="#terms">Terms and Conditions</a> and <a href="#privacy">Privacy Statement</a></p>
                            {message && <div className='alert alert-danger'>{message}</div>}
                            <button type="submit" className="btn btn-primary">Create account</button>
                        </form>
                        <p>Already have an account? <Link to="/sign-in">Log in</Link></p>
                    </div>
                </div>
                <div className="signup-right">
                    <img src={'../assets/top-image.png'} alt="top-right-image" className="top-right-image" />
                    <img src={'../assets/Group.png'} alt="Logo" className="logo" />
                    <img src={'../assets/button-image.png'} alt="bottom-left-image" className="bottom-left-image" />
                </div>
            </div>
        </>
    );
};

export default SignUp;
