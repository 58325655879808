import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DefaultLayout from './components/DefaultLayout/DefaultLayout';
import AuthLayout from './components/AuthLayout';
import SignIn from './pages/SignIn/SignIn';
import SignUp from './pages/SignUp/SignUp';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ConfirmAccount from './pages/ConfirmAccount/ConfirmAccount';
import PasswordReset from './pages/PasswordReset/PasswordReset';
import OTPVerification from './pages/OTPVerification/OTPVerification';
import NotFound from './pages/NotFound/NotFound';
import UpdatePassword from './pages/UpdatePassword/UpdatePassword';

function App() {
    return (
        <Router>
            <div>
                <Routes>
                    <Route path="/sign-in" element={<AuthLayout><SignIn /></AuthLayout>} />
                    <Route path="/sign-up" element={<AuthLayout><SignUp /></AuthLayout>} />
                    <Route path="/forgot-password" element={<AuthLayout><ForgotPassword /></AuthLayout>} />
                    <Route path="/confirm-account" element={<AuthLayout><ConfirmAccount /></AuthLayout>} />
                    <Route path="/password-reset" element={<AuthLayout><PasswordReset /></AuthLayout>} />
                    <Route path="/OTP-verification" element={<AuthLayout><OTPVerification /></AuthLayout>} />
                    <Route path="/update-password" element={<AuthLayout><UpdatePassword /></AuthLayout>} />


                    <Route path="/*" element={<DefaultLayout />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
