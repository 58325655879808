import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Joi from 'joi';
import { Helmet } from 'react-helmet';
import './ChangePassword.css';

const ChangePassword = () => {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const schema = Joi.object({
        oldPassword: Joi.string().required().label('Old Password'),
        newPassword: Joi.string()
            .min(8)
            .pattern(new RegExp('^(?=.*[a-z])(?=.*[A-Z]).+$'))
            .required()
            .label('New Password')
            .messages({
                'string.pattern.base': 'New Password must contain both uppercase and lowercase letters'
            }),
        confirmPassword: Joi.string()
            .valid(Joi.ref('newPassword'))
            .required()
            .label('Confirm Password')
            .messages({
                'any.only': 'Confirm Password does not match New Password'
            }),
    });

    const validate = () => {
        const { error } = schema.validate({ oldPassword, newPassword, confirmPassword }, { abortEarly: false });
        if (!error) return null;

        const validationErrors = {};
        for (let item of error.details) {
            validationErrors[item.path[0]] = item.message;
        }
        return validationErrors;
    };

    useEffect(() => {
        if (submitted) {
            const validationErrors = validate();
            setErrors(validationErrors || {});
        }
    }, [oldPassword, newPassword, confirmPassword, submitted]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors || {});
        setSubmitted(true);
        if (validationErrors) return;

        try {
            const response = await axios.post('https://your-api-endpoint.com/change-password', {
                oldPassword,
                newPassword
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer YOUR_AUTH_TOKEN`
                }
            });

            if (response.status === 200) {
                console.log('Password changed successfully');
            } else {
                console.log('Failed to change password');
            }
        } catch (error) {
            console.error('Error changing password:', error);
        }
    };

    const toggleShowOldPassword = () => setShowOldPassword(!showOldPassword);
    const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

    return (
        <div className="change-password-container">
            <Helmet>
                <title>Change Password</title>
            </Helmet>
            <div className="change-password-content">
                <form className="change-password-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Old Password</label>
                        <div className="password-input">
                            <input
                                type={showOldPassword ? "text" : "password"}
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                placeholder="Enter your old password"
                            />
                            <span className="password-toggle-text" onClick={toggleShowOldPassword}>
                                {showOldPassword ? "Hide" : "Show"}
                            </span>
                        </div>
                        {submitted && errors.oldPassword && <div className="error-message">{errors.oldPassword}</div>}
                    </div>
                    <div className="form-group">
                        <label>New Password</label>
                        <div className="password-input">
                            <input
                                type={showNewPassword ? "text" : "password"}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                placeholder="Enter your new password"
                            />
                            <span className="password-toggle-text" onClick={toggleShowNewPassword}>
                                {showNewPassword ? "Hide" : "Show"}
                            </span>
                        </div>
                        {submitted && errors.newPassword && <div className="error-message">{errors.newPassword}</div>}
                    </div>
                    <div className="form-group">
                        <label>Confirm Password</label>
                        <div className="password-input">
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="Confirm your new password"
                            />
                            <span className="password-toggle-text" onClick={toggleShowConfirmPassword}>
                                {showConfirmPassword ? "Hide" : "Show"}
                            </span>
                        </div>
                        {submitted && errors.confirmPassword && <div className="error-message">{errors.confirmPassword}</div>}
                    </div>
                    <button type="submit" className="change-password-button">Change Password</button>
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;
