import React from 'react';
import { Helmet } from 'react-helmet';
// import Omnichannels from './Omnichannels';
// import PerformanceChart from './PerformanceChart';
import AllOmnichannels from './allOmnichannels';
import { Link } from 'react-router-dom';

import './Dashboard.css';

const Dashboard = () => {
    return (
        <div className="dashboard">
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <div className="container-fluid">
                
                    {/* <h3 className='mt-4 ml-4 mb-3'>Omnichannel</h3> */}
                    <div className="row pt-5 pr-4 pl-4 pb-3">
                        <div className="col-md-4 pr-0 mt-2">
                            <span className="ml-2 text-bold">Omnichannel</span>
                        </div>
                        <div className="col-md-8 d-flex flex-column pl-0">
                            <div className="d-flex justify-content-end align-items-center">
                                <Link to="/messages">
                                    <button className="btn me-2 round-pill btn-dark-gray">
                                        Messages
                                    </button> 
                                </Link>
                            </div>
                        </div>
                    </div>
               
                <AllOmnichannels />
                
            </div>
        </div>
    );      
};

export default Dashboard;
