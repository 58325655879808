import React, { useState } from 'react';
import './OTPVerification.css';
import { Helmet } from 'react-helmet';

const OTPVerification = () => {
    const [otp, setOtp] = useState(new Array(4).fill(""));

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <>
        <Helmet>
            <title>OTP Verification</title>
        </Helmet>
        <div className="otp-verification-container d-flex vh-100">
            <div className="otp-verification-left d-flex flex-column justify-content-center align-items-center bg-white p-4">
                <div className="otp-verification-form w-100" style={{ maxWidth: '400px' }}>
                    <h2 className="text-start">Enter Verification Code</h2>
                    <p className="text-start">Enter a 6-digit confirmation code sent to your email</p>
                    <form onSubmit={handleSubmit}>
                        <div className="otp-input-container d-flex justify-content-between mb-4">
                            {otp.map((data, index) => {
                                return (
                                    <input
                                        className="form-control otp-input text-center"
                                        type="text"
                                        name="otp"
                                        maxLength="1"
                                        key={index}
                                        value={data}
                                        onChange={(e) => handleChange(e.target, index)}
                                    />
                                );
                            })}
                        </div>
                        <button style={{background:"#39B7E9", color:"white"}} type="submit" className="btn  w-100 mb-3">Submit</button>
                    </form>
                    <p className="text-start"><a href="/sign-in">Return to log in</a></p>
                    <p className="text-start">Didn't receive OTP via email? <a href="/resend-otp">Send code again</a></p>
                </div>
            </div>
            <div className="signup-right">
                <img src={'../assets/top-image.png'} alt="top-right-image" className="top-right-image" />
                <img src={'../assets/Group.png'} alt="Logo" className="logo" />
                <img src={'../assets/button-image.png'} alt="bottom-left-image" className="bottom-left-image" />
            </div>
        </div>
        </>
       
    );
};

export default OTPVerification;
