import React from 'react';
import './Numbers.css';

const Numbers = () => {
    const numbersData = [
        {
            number: '44220485865',
            country: 'UK',
            type: '+44',
            fee: '₦483.2',
            dateRented: '2022-08-09',
            expiryDate: '2022-08-09',
            status: 'Active',
        },
        {
            number: '12345678901',
            country: 'US',
            type: '+1',
            fee: '₦550.0',
            dateRented: '2023-01-15',
            expiryDate: '2023-01-15',
            status: 'Expired',
        },
        {
            number: '98765432100',
            country: 'CA',
            type: '+1',
            fee: '₦600.0',
            dateRented: '2023-03-22',
            expiryDate: '2023-03-22',
            status: 'Active',
        },
        {
            number: '33123456789',
            country: 'FR',
            type: '+33',
            fee: '₦500.0',
            dateRented: '2023-06-20',
            expiryDate: '2023-06-20',
            status: 'Inactive',
        },
        
    ];

    return (
        <div className="numbers">
            <div className="header">
                <h2 className='mr-3'>Numbers</h2>
                <button className="rent-number-button">Rent a Number</button>
            </div>
            <p>Reach your customers easily in other regions by using numbers with their country code</p>
            <div className="invoice-container">
                <table className="invoice-table">
                    <thead>
                        <tr>
                            <th>NUMBER</th>
                            <th>COUNTRY</th>
                            <th>NO. TYPE</th>
                            <th>FEE</th>
                            <th>DATE RENTED</th>
                            <th>EXPIRY DATE</th>
                            <th>STATUS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {numbersData.map((item, index) => (
                            <tr key={index}>
                                <td>{item.number}</td>
                                <td>{item.country}</td>
                                <td>{item.type}</td>
                                <td>{item.fee}</td>
                                <td>{item.dateRented}</td>
                                <td>{item.expiryDate}</td>
                                <td>
                                    <span className={`status ${item.status.toLowerCase()}`}>
                                        {item.status}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Numbers;
