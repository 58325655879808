import React, { useState } from 'react';
// import axios from 'axios';
import './allOmnichannels.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {} from '@fortawesome/free-brands-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Modal, Button } from 'react-bootstrap';

const AllOmnichannels = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const scriptSnippet = `<script async src="https://messaging.nativetalkapp.com/scripts/chat-widget-74562672.js"></script>`;

  // Function to copy text to clipboard
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(scriptSnippet).then(() => {
      toast.success('Code copied to clipboard');
    }).catch(err => {
      console.error("Failed to copy: ", err);
    });
  };

  // Function to handle Facebook Connect
  const handleFacebookConnect = () => {
    // Redirect user to Facebook authorization URL with Bearer Token if necessary
    const facebookAuthorizeUrl = `https://messaging.nativetalkapp.com/fb/authorize`;
    window.location.href = facebookAuthorizeUrl;
  };


  return (
    <div className="container">
      <ToastContainer />
      <div className="row justify-content-center">
        <div className="col-12 col-md-12 bg-white text-black p-3">
          <div className="row justify-content-center m-5">
            <div className="col-7 col-md-7 p-3">

              {/* Facebook */}
              <div className="card mb-3">
                <div className="row g-0 align-items-center"> 
                  <div className="col-md-1 text-center">
                    <img src="../assets/facebook.svg" alt="Facebook" className="m-3" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title mb-0">Facebook</h5>
                      <p className="card-text">
                        <small className="text-body-secondary gray100">Send and receive Facebook messages via your inbox</small>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 text-center">
                    <button className="btn btn-connect btn-sm rounded-pill" onClick={handleFacebookConnect} >Connect</button>
                  </div>
                </div>
              </div>

              {/* Instagram */}
              <div className="card mb-3">
                <div className="row g-0 align-items-center"> 
                  <div className="col-md-1 text-center">
                    <img src="../assets/instagram.svg" alt="Facebook" className="m-3" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title mb-0">Instagram</h5>
                      <p className="card-text">
                        <small className="text-body-secondary gray100">Send and receive Instagram messages via your inbox</small>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 text-center">
                    <button className="btn btn-connect btn-sm rounded-pill">Connect</button>
                  </div>
                </div>
              </div>

              {/* Whatsapp */}
              <div className="card mb-3">
                <div className="row g-0 align-items-center"> 
                  <div className="col-md-1 text-center">
                    <img src="../assets/whatsapp.svg" alt="Facebook" className="m-3" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title mb-0">Whatsapp</h5>
                      <p className="card-text">
                        <small className="text-body-secondary gray100">Send and receive Whatsapp messages via your inbox</small>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 text-center">
                    <button className="btn btn-connect btn-sm rounded-pill">Connect</button>
                  </div>
                </div>
              </div>
              
              {/* SMS */}
              <div className="card mb-3">
                <div className="row g-0 align-items-center"> 
                  <div className="col-md-1 text-center">
                    <img src="../assets/sms.svg" alt="Facebook" className="m-3" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title mb-0">SMS</h5>
                      <p className="card-text">
                        <small className="text-body-secondary gray100">Send and receive SMS via your inbox</small>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 text-center">
                    <button className="btn btn-connect btn-sm rounded-pill">Connect</button>
                  </div>
                </div>
              </div>

              {/* Nativetalk Livechat */}
              <div className="card mb-3">
                <div className="row g-0 align-items-center"> 
                  <div className="col-md-1 text-center">
                    <img src="../assets/livechat.svg" alt="Facebook" className="m-3" />
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title mb-0">Nativetalk Livechat</h5>
                      <p className="card-text">
                        <small className="text-body-secondary gray100">Send and receive Live chat messages via your inbox</small>
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 text-center">
                    <button className="btn btn-connect btn-sm rounded-pill" onClick={handleShowModal}>Connect</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      {/* Modal */}
      {showModal && (

        <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title>Live Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <small>Please copy the script to embed in your website:</small>
          <div className="script-snippet-container mt-3 p-4 d-flex align-items-center justify-content-between border rounded">
            <code className="script-snippet word-break">{scriptSnippet}</code>
            <FontAwesomeIcon icon={faCopy} className="copy-icon" onClick={handleCopyToClipboard} />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="outline-secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" className='btn-copy' onClick={handleCopyToClipboard}>
            Copy Code
          </Button>
        </Modal.Footer>
        </Modal>

      )}

    </div>

  );
};

export default AllOmnichannels;
