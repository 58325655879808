import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DetailedTransactionHistory.css';
import useOnClickOutside from './useOnClickOutside';
import Pagination from "../../components/Pagination/Pagination";

const detailedTransactions = [
    { date: '2023-08-01 10:28:14', balanceBefore: '₦10,000', amount: '₦2,000', balanceAfter: '₦8,000', type: 'Test Credit' },
    { date: '2023-08-02 11:15:24', balanceBefore: '₦8,000', amount: '₦1,000', balanceAfter: '₦7,000', type: 'Test Credit' },
    { date: '2023-08-01 10:28:14', balanceBefore: '₦10,000', amount: '₦2,000', balanceAfter: '₦8,000', type: 'Test Credit' },
    { date: '2023-08-02 11:15:24', balanceBefore: '₦8,000', amount: '₦1,000', balanceAfter: '₦7,000', type: 'Test Credit' },
    { date: '2023-08-01 10:28:14', balanceBefore: '₦10,000', amount: '₦2,000', balanceAfter: '₦8,000', type: 'Test Credit' },
    { date: '2023-08-02 11:15:24', balanceBefore: '₦8,000', amount: '₦1,000', balanceAfter: '₦7,000', type: 'Test Credit' },
    { date: '2023-08-01 10:28:14', balanceBefore: '₦10,000', amount: '₦2,000', balanceAfter: '₦8,000', type: 'Test Credit' },
    { date: '2023-08-02 11:15:24', balanceBefore: '₦8,000', amount: '₦1,000', balanceAfter: '₦7,000', type: 'Test Credit' },
    { date: '2023-08-01 10:28:14', balanceBefore: '₦10,000', amount: '₦2,000', balanceAfter: '₦8,000', type: 'Test Credit' },
    { date: '2023-08-02 11:15:24', balanceBefore: '₦8,000', amount: '₦1,000', balanceAfter: '₦7,000', type: 'Test Credit' },
    { date: '2023-08-01 10:28:14', balanceBefore: '₦10,000', amount: '₦2,000', balanceAfter: '₦8,000', type: 'Test Credit' },
    { date: '2023-08-02 11:15:24', balanceBefore: '₦8,000', amount: '₦1,000', balanceAfter: '₦7,000', type: 'Test Credit' },
];

const ITEMS_PER_PAGE = 7;

const DetailedTransactionHistory = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [showDatePicker, setShowDatePicker] = useState(false);
    const datePickerRef = useRef();

    const totalPages = Math.ceil(detailedTransactions.length / ITEMS_PER_PAGE);
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const currentTransactions = detailedTransactions.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    const handleDateRangeConfirm = () => {
        console.log('Selected Date Range:', { startDate, endDate });
        setDateRange([null, null]);
        setShowDatePicker(false);
    };

    useOnClickOutside(datePickerRef, () => setShowDatePicker(false));

    return (
        <div className="detailed-transaction-history__container">
            <Helmet>
                <title>Detailed Transaction History</title>
            </Helmet>
            <div className='d-flex align-items-center header-section'>
                <h2>Transaction History</h2>
                <div className="transaction-summary">
                    <span>Amount Funded: <strong className='export-button'>₦3,000.00</strong></span>
                    <span>Amount Spent: <strong className='export-button'>₦3,000.00</strong></span>
                </div>
            </div>
            <div className="transaction-actions">
                <div className="date-range-picker" ref={datePickerRef}>
                    <button className="filter-date-range-button" onClick={() => setShowDatePicker(!showDatePicker)}>
                        <FontAwesomeIcon icon={faCalendarAlt} />
                        <span>Date range</span>
                    </button>
                    {showDatePicker && (
                        <div className="date-picker-container">
                            <DatePicker
                                selected={startDate}
                                onChange={(update) => setDateRange(update)}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                                inline
                                className="date-picker-input"
                            />
                            <button className="confirm-button" onClick={handleDateRangeConfirm}>OK</button>
                        </div>
                    )}
                </div>
                <button className="filter-button">Filter</button>
                <button className="export-button">Export</button>
            </div>
            <table className="detailed-transaction-history__table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Balance Before</th>
                        <th>Amount</th>
                        <th>Balance After</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody>
                    {currentTransactions.map((transaction, index) => (
                        <tr key={index}>
                            <td>{transaction.date}</td>
                            <td>{transaction.balanceBefore}</td>
                            <td>{transaction.amount}</td>
                            <td>{transaction.balanceAfter}</td>
                            <td>{transaction.type}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={setCurrentPage}
                startIndex={startIndex}
                itemsPerPage={ITEMS_PER_PAGE}
                totalItems={detailedTransactions.length}
            />
        </div>
    );
};

export default DetailedTransactionHistory;
