import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import './MessagingInsight.css';

// Data without color properties
const data = [
  { status: 'Sent', value: 400 },
  { status: 'Rejected', value: 100 },
  { status: 'Delivered', value: 100 },
  { status: 'Invalid Number', value: 100 },
  { status: 'Received', value: 200 },
  { status: 'Failed', value: 200 },
  { status: 'Read', value: 300 },
  { status: 'Invalid Message Text', value: 100 },
];

const barData = [
  { status: 'Sent', value: 1 },
  { status: 'Rejected', value: 0.25 },
  { status: 'Delivered', value: 0.25 },
  { status: 'Invalid Number', value: 0.25 },
  { status: 'Received', value: 0.5 },
  { status: 'Failed', value: 0.5 },
  { status: 'Read', value: 0.75 },
  { status: 'Invalid Message Text', value: 0.25 },
];

// Define a color map
const colorMap = {
  Sent: '#62b5e5',
  Rejected: '#ff4040',
  Delivered: '#8bc24a',
  'Invalid Number': '#ccc',
  Received: '#36c97b',
  Failed: '#ffc107',
  Read: '#3e7d9a',
  'Invalid Message Text': '#ddd',
};

const CustomLegend = () => {
  return (
    <div className="custom-legend">
      {data.map((entry, index) => (
        <div key={`legend-${index}`} className="legend-item">
          <div
            className="color-circle"
            style={{ backgroundColor: colorMap[entry.status] }}
          ></div>
          <span className="legend-text">{entry.status.toUpperCase()}</span>
        </div>
      ))}
    </div>
  );
};

const MessagingInsight = () => {
  return (
    <div className="messaging-insight-container container mt-5">
      <CustomLegend />
      <div className="d-flex justify-content-center align-items-start mt-3">
        <div className="d-flex flex-column align-items-center">
          <ResponsiveContainer width={300} height={300}>
            <PieChart>
              <Pie
                data={data}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                labelLine={false}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colorMap[entry.status]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="ml-5">
          <ResponsiveContainer width={500} height={300}>
            <BarChart
              data={barData}
              margin={{ top: 0, right: 50, left: 0, bottom: 5 }}
            >
              <XAxis
                dataKey="status"
                angle={-45}
                textAnchor="end"
                height={70}
                interval={0}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#8884d8">
                {barData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colorMap[entry.status]}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default MessagingInsight;
