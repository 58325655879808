import React, { useState } from 'react';
import { Helmet } from 'react-helmet'; 
import axios from 'axios';
import './DeactivateAccount.css';

const DeactivateAccount = () => {
    const [reason, setReason] = useState('');

    const handleChange = (event) => {
        setReason(event.target.value);
    };

    const handleDeactivate = () => {
        if (reason.trim() === '') {
            console.log('Please provide a reason for deactivation.');
            return;
        }

        const apiUrl = 'https://your-api-endpoint.com/deactivate';

        axios.post(apiUrl, { reason })
            .then(response => {
                if (response.data.success) {
                    console.log('Your account has been deactivated.');
                } else {
                    console.log('There was a problem deactivating your account. Please try again.');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                console.log('There was a problem with the request. Please try again.');
            });
    };

    return (
        <div className='deactivate-account'>
            <Helmet>
                <title>Deactivate Account</title> 
            </Helmet>
            <textarea 
                placeholder='Help us understand why you want to leave. We will use your feedback to get better.' 
                name="deactivateReason" 
                id="deactivateReason"
                value={reason}
                onChange={handleChange}
            ></textarea>
            <button onClick={handleDeactivate}>Deactivate Account</button>
        </div>
    );
};

export default DeactivateAccount;
