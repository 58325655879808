import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import './messageList.css'; // Optional if you want custom styles
import { toast, ToastContainer } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';

const MessageList = () => {
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [messageInput, setMessageInput] = useState(''); 
    const chatContainerRef = useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJpYXQiOjE3MjE5MDU5NzEsImV4cCI6MjAzODEyOTk3MX0.pIPOLdEtJKK0FDZ3_lRYMrRW_EhgMGKY51FlFBsSwNQ';  // Replace with your actual token
    const socket = io('https://messaging.nativetalkapp.com');  // Connect to the Socket.IO server
    const userIdLivechat = "Livechat"; // The user ID for livechat chat room
    const userIdFacebook = "facebook";
    const clientId = '2'; //make it dynamic

    const location = useLocation();

    // Parse the access token from the URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const accessToken = queryParams.get('token');

    // Effect to save the Facebook token to the server if present in the URL
    useEffect(() => {
        if (accessToken) {
            // Store the access token locally
            localStorage.setItem('fbAccessToken', accessToken);

            // Make the POST request to save the Facebook token to the server
            const saveFacebookToken = async () => {
                try {
                    const response = await axios.post(
                        'https://messaging.nativetalkapp.com/v1/service/business/save-facebook-token',
                        { token: accessToken },  // Pass the Facebook token in the body
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`,  // Pass the bearer token in the headers
                                'Content-Type': 'application/json'
                            }
                        }
                    );

                    // Check if the request was successful
                    if (response.status === 200 && response.data.ok) {
                        console.log('Facebook token saved successfully:', response.data);
                        toast.success('Facebook integrated successfully');

                    } else {
                        console.error('Failed to save Facebook token:', response.data);
                        setError(response.data.message);
                        toast.error('Failed to integrate facebook');
                    }
                } catch (error) {
                    console.error('Error saving Facebook token:', error);
                    setError('Failed to integrate facebookm, server error');
                    toast.error('Failed to integrate facebook');
                }
            };

            // Call the function to save the token
            saveFacebookToken();
        }
    }, [accessToken, token]);

    // Fetch contacts on component mount
    useEffect(() => {
        const fetchContacts = async () => {
            console.log('fecth contacts');
            try {
                // Fetch livechat contacts
                const livechatResponse = await axios.get(
                    `https://messaging.nativetalkapp.com/v1/service/history/contacts?sender_number=${userIdLivechat}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );

                // Fetch Facebook contacts
                const facebookResponse = await axios.get(
                    `https://messaging.nativetalkapp.com/v1/service/history/contacts?sender_number=${userIdFacebook}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );

                // Combine contacts from both responses
                if (livechatResponse.data.ok && facebookResponse.data.ok) {
                    const combinedContacts = [
                        ...livechatResponse.data.data,
                        ...facebookResponse.data.data
                    ];
                    setContacts(combinedContacts);
                } else {
                    setError('Failed to load contacts');
                }

                setLoading(false);
            } catch (error) {
                setError('Error fetching contacts');
                setLoading(false);
            }
        };

        fetchContacts();

        // Set up Socket.IO connection
        socket.emit('join_room', clientId); // Join the room with user ID
        console.log("Joined by:", clientId);

        // Listen for new messages via Socket.IO
        socket.on('new_message', (message) => {
            console.log('New message received:', message);

            fetchContacts();

            if (message.sender_number === selectedContact?.contact) {
                // If message is from the currently opened contact, update the message list
                setMessages((prevMessages) => [...prevMessages, message]);
            } else {
                // If message is from another contact, notify in the contact list
                setContacts((prevContacts) =>
                    prevContacts.map((contact) =>
                        contact.contact === message.sender_number
                            ? { ...contact, message_text: message.message_text } // Update preview text in contact list
                            : contact
                    )
                );
            }
        });

        return () => {
            console.log('disconnect');
            socket.disconnect(); // Clean up the socket connection when the component unmounts
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContact]);


    // Fetch messages when a contact is selected
    useEffect(() => {
        const fetchMessages = async () => {
            if (!selectedContact) return;

            try {
                const response = await axios.get(
                    `https://messaging.nativetalkapp.com/v1/service/history?sender_number=${selectedContact.channel}&recipient_number=${selectedContact.contact}`,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );

                if (response.data.ok) {
                    setMessages(response.data.data);
                } else {
                    setError('Failed to load messages');
                }

                setLoading(false);
            } catch (error) {
                setError('Error fetching messages');
                setLoading(false);
            }
        };

        fetchMessages();
    }, [selectedContact]);

    const handleContactSelect = (contact) => {
        setSelectedContact(contact);
        setMessages([]);  // Clear messages when a new contact is selected
    };

    const handleSendMessage = async () => {
        if (messageInput.trim() === '') return;

        // Find the most recent message where sender_number equals selectedContact.contact
        const mostRecentMessage = messages
        .filter(message => message.sender_number === selectedContact.contact)
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];


        const newMessage = {
            recipient_number: selectedContact.contact,
            sender_number: userIdLivechat,
            message_text: messageInput,
            recipient_name: selectedContact.contact,
            sender_name: selectedContact.channel,
            channel: selectedContact.channel,
            created_at: new Date().toISOString(),
            message_id: mostRecentMessage ? mostRecentMessage.id : null
        };

        let url = 'https://messaging.nativetalkapp.com/v1/service/send';  // Initialize the URL based on the channel

        switch (selectedContact.channel) {
            case 'livechat': 
                url = 'https://messaging.nativetalkapp.com/v1/service/send';
                break;
            case 'facebook':
                url = 'https://messaging.nativetalkapp.com/v1/service/fb/reply';
                break;
            case 'instagram':
                url = 'https://messaging.nativetalkapp.com/v1/service/instagram/reply';
                break;
            case 'whatsapp':
                url = 'https://messaging.nativetalkapp.com/v1/service/whatsapp/reply';
                break;
            default:
                url = 'https://messaging.nativetalkapp.com/v1/service/send';
        }

        try {
            const response = await axios.post(url, newMessage, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.ok) {
                setMessages((prevMessages) => [...prevMessages, newMessage]);  // Append message to local chat
                setMessageInput('');  // Clear input field
            }
        } catch (error) {
            console.error('Failed to send message:', error);
        }
    };

    const handleMoreClick = () => {
        setIsDropdownOpen(!isDropdownOpen);  // Toggle dropdown visibility
    };   
    
    const handleMenuAction = (menu) => {
        console.log(menu)
    };  

    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    // Scroll to bottom when messages change
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <div className='row bg-gray1'>
            <ToastContainer />
            <div className="container mt-4 pt-2">
                <div className="row">
                    <div className="col-md-4 pr-0 mt-2">
                        {/* <FontAwesomeIcon className='' icon={faArrowLeft} /> */}
                        <span className="ml-2 text-bold">Messages</span>
                    </div>
                    <div className="col-md-8 d-flex flex-column pl-0">
                        <div className="d-flex justify-content-end align-items-center">
                            <Link to="/dashboard">
                                <button className="btn me-2 round-pill btn-dark-gray">
                                    Connect Channel
                                </button> 
                            </Link>

                            <img src="../assets/filter.svg" alt="Filter" className="ms-2 ml-3" />

                        </div>
                    </div>
                </div>
                <div className="row bg-white mt-3">
                    {/* Left hand side - Contact List */}
                    <div className="col-md-4 pr-0 border-right">
                        
                        {loading && <p>Loading contacts...</p>}
                        {error && <p className="text-danger">{error}</p>}
                        <div className='row mt-4 ml-2'>
                            <img src="../assets/chat_dots.svg" alt="Filter" className="" />
                            <small className='ml-1 gray100'>All messages</small>
                        </div>
                        <ul className="list-group list-group-flush mr-3">
                            {contacts.map(contact => (
                                <li
                                key={contact.id}
                                className={`list-group-item list-group-item-action d-flex align-items-center justify-content-between ${selectedContact && selectedContact.contact === contact.contact ? 'selected' : ''}`}
                                onClick={() => handleContactSelect(contact)}
                                >
                                <div className="d-flex align-items-center">
                                    {/* Profile image with dynamic icon based on the channel */}
                                    <div className="position-relative">
                                    
                                    <FontAwesomeIcon
                                        icon={faUserCircle}
                                        className="contact-img rounded-circle"
                                    />

                                    {/* Conditionally render the channel icon */}
                                    <img
                                        src={
                                        contact.channel === 'livechat'
                                            ? '../assets/livechat.svg'
                                            : contact.channel === 'facebook'
                                            ? '../assets/facebook.svg'
                                            : contact.channel === 'instagram'
                                            ? '../assets/instagram.svg'
                                            : contact.channel === 'whatsapp'
                                            ? '../assets/whatsapp.svg'
                                            : '../assets/livechat.svg' // Fallback if channel doesn't match any known options
                                        }
                                        alt={contact.channel}
                                        className="position-absolute bottom-0 end-0 channel-icon"
                                        style={{ fontSize: '14px', width: '20px', backgroundColor: 'white', borderRadius: '50%' }}
                                    />
                                    </div>

                                    {/* Contact name and message preview */}
                                    <div className="ml-3">
                                    <h6 className="mb-0">{contact.contact}</h6>
                                    <p className="mb-0 text-muted small lines-2">{contact.message_text}</p>
                                    </div>
                                </div>
                                {/* Date at the top right */}
                                <small className="text-muted">{new Date(contact.created_at).toLocaleDateString()}</small>
                                </li>
                            ))}
                            </ul>


                    </div>

                    <div className="col-md-8 d-flex flex-column pl-0 border-0 vh-100 mb-2">
                        
                    {selectedContact ? (
                        <>
                        {/* Chat header */}
                        <div className="card mb-1 mt-1 border-0">
                            <div className="card-body p-0">
                            <div className="row">
                                {/* Left: Centered Element */}
                                <div className="col-md-10 d-flex align-items-center justify-content-center">
                                <div className="row g-0 align-items-center">
                                    <div className="col-md-2 offset-md-2 text-center">
                                    <FontAwesomeIcon className='m-3 fa-2x' icon={faUserCircle} />
                                    </div>
                                    <div className="col-md-8">
                                    <div className="card-body">
                                        <h5 className="card-title mb-0">{selectedContact.contact}</h5>
                                    </div>
                                    </div>
                                </div>
                                </div>

                                {/* Right: Icon */}
                                {/* <div className="col-md-2 d-flex justify-content-end align-items-center">
                                <img src="../assets/more.svg" alt="Right Icon" className="m-3" />
                                </div> */}

                                <div className="col-md-2 d-flex justify-content-end align-items-center position-relative">
                                    <img 
                                        src="../assets/more.svg" 
                                        alt="Right Icon" 
                                        className="m-3" 
                                        onClick={handleMoreClick} 
                                        style={{ cursor: 'pointer' }} 
                                    />
                                    
                                    <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''} position-absolute`}>
                                        <button className="dropdown-item" onClick={() => handleMenuAction('add_customer')}>Add as customer</button>
                                        <button className="dropdown-item" onClick={() => handleMenuAction('create_ticket')}>Create a ticket</button>
                                        <button className="dropdown-item" onClick={() => handleMenuAction('assign_agent')}>Assign to agent</button>
                                        <button className="dropdown-item" onClick={() => handleMenuAction('notes')}>Notes</button>
                                    </div>
                                </div>


                            </div>
                            </div>
                        </div>

                        {/* Chat messages container (flex-grow to fill remaining space) */}
                        <div className="chat-container flex-grow-1 overflow-auto pb-3 bg-gray" ref={chatContainerRef}>
                            {messages.length > 0 ? (
                            messages.map((message) => (
                                <div
                                key={message.id}
                                className={`d-flex mb-2 ${message.sender_number === selectedContact.contact ? 'justify-content-start mr-5' : 'justify-content-end ml-5'}`}
                                >
                                <div className={`message-bubble p-2 rounded ${message.sender_number === selectedContact.contact ? 'bg-light' : 'theme-bg text-white'}`}>
                                    <p className="mb-0">{message.message_text}</p>
                                    <small>{new Date(message.created_at).toLocaleString()}</small>
                                </div>
                                </div>
                            ))
                            ) : (
                            <p>No messages found</p>
                            )}
                        </div>

                        {/* Input field to send a message */}
                        <div className="input-group p-3 bg-gray">
                            <textarea
                                className="form-control message-input p-3"
                                placeholder="Type a message..."
                                value={messageInput}
                                rows="1"  // Minimum row height
                                onChange={(e) => setMessageInput(e.target.value)}
                                style={{ resize: "none" }} // Prevent manual resizing of the textarea
                            />
                            <div className="input-group-append">
                                <button className="btn theme-bg mr-3" onClick={handleSendMessage}>
                                <FontAwesomeIcon className="text-white" icon={faPaperPlane} />
                                </button>
                            </div>
                        </div>

                        </>
                    ) : (
                        <div className="d-flex justify-content-center align-items-center vh-100">
                        <h4>Select a contact</h4>
                        </div>
                    )}
                    </div>


                </div>
            </div>
        </div>
    );
};

export default MessageList;
