import React, { useState } from 'react';
import axios from 'axios';

const ReportComponent = () => {
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [keys, setKeys] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [fromTime, setFromTime] = useState('');
    const [fromSeconds, setFromSeconds] = useState('00');
    const [toDate, setToDate] = useState('');
    const [toTime, setToTime] = useState('');
    const [toSeconds, setToSeconds] = useState('00');

    // Function to format date and time to "YYYY-MM-DD HH:MM:SS"
    const formatDateTime = (date, time, seconds) => {
        const formattedSeconds = seconds.padStart(2, '0');
        return `${date} ${time}:${formattedSeconds}`;
    };

    const fetchReports = async () => {
        setLoading(true);
        setError(null);

        const payload = {
            id: "1",
            token: "VWJsaWJsemY3LzIxL0hGRS8rSld0Zz09",
            action: "clicktocall_report",
            object_where_params: {
                from_date: formatDateTime(fromDate, fromTime, fromSeconds),
                to_date: formatDateTime(toDate, toTime, toSeconds),
                callerid: "",
                sender: "",
                reciever: "",
                accountid: "",
                reseller_id: "",
                ivr_id: "",
                uniqueid: "",
                keys: keys,
                audio_file: ""
            },
            start_limit: "1",
            end_limit: "8"
        };

        try {
            const { data } = await axios.post('http://127.0.0.1:5000/api/click_to_call/get_call_reports', payload);
            if (data.status === true) {
                setReports(data.data || []);
            } else {
                setError('Failed to fetch reports');
            }
        } catch (error) {
            console.error('Error fetching reports:', error);
            setError('An error occurred while fetching reports');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (keys && fromDate && fromTime && fromSeconds && toDate && toTime && toSeconds) {
            if (new Date(formatDateTime(fromDate, fromTime, fromSeconds)) > new Date(formatDateTime(toDate, toTime, toSeconds))) {
                setError('From Date and Time must be before To Date and Time');
                return;
            }
            fetchReports();
        } else {
            setError('Please fill in all fields');
        }
    };

    return (
        <div className="container mt-5">
            <h1 className="mb-4">Report Data</h1>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <label className="form-label">Keys:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={keys}
                        onChange={(e) => setKeys(e.target.value)}
                    />
                </div>

                <div className="mb-4">
                    <label className="form-label">From Date and Time:</label>
                    <div className="row">
                        <div className="col">
                            <input
                                type="date"
                                className="form-control"
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <input
                                type="time"
                                className="form-control"
                                value={fromTime}
                                onChange={(e) => setFromTime(e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <input
                                type="number"
                                className="form-control"
                                min="0"
                                max="59"
                                value={fromSeconds}
                                onChange={(e) => setFromSeconds(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <label className="form-label">To Date and Time:</label>
                    <div className="row">
                        <div className="col">
                            <input
                                type="date"
                                className="form-control"
                                value={toDate}
                                onChange={(e) => setToDate(e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <input
                                type="time"
                                className="form-control"
                                value={toTime}
                                onChange={(e) => setToTime(e.target.value)}
                            />
                        </div>
                        <div className="col">
                            <input
                                type="number"
                                className="form-control"
                                min="0"
                                max="59"
                                value={toSeconds}
                                onChange={(e) => setToSeconds(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <button type="submit" className="btn btn-primary">Get Reports</button>
            </form>

            {loading && (
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

            {error && <p className="text-danger mt-3">{error}</p>}

            {reports.length > 0 && !loading && (
                <div className="table-responsive mt-5">
                    <table className="table table-bordered table-hover">
                        <thead className="table-light">
                            <tr>
                                <th>ID</th>
                                <th>Account ID</th>
                                <th>DMTF</th>
                                <th>Reseller ID</th>
                                <th>Caller ID</th>
                                <th>Sender</th>
                                <th>Receiver</th>
                                <th>IVR ID</th>
                                <th>Status</th>
                                <th>Call Start</th>
                                <th>End Stamp</th>
                                <th>Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reports.map((report, index) => (
                                <tr key={index}>
                                    <td>{report.id}</td>
                                    <td>{report.accountid}</td>
                                    <td>{report.dtmf}</td>
                                    <td>{report.reseller_id}</td>
                                    <td>{report.callerid}</td>
                                    <td>{report.sender}</td>
                                    <td>{report.reciever}</td>
                                    <td>{report.ivr_id}</td>
                                    <td>{report.status}</td>
                                    <td>{report.callstart}</td>
                                    <td>{report.end_stamp}</td>
                                    <td>{report.duration}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default ReportComponent;
