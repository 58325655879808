import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './MessagingReport.css';
import Pagination from '../../components/Pagination/Pagination';

const MessagingReport = () => {
    const messages = [
        {
            description: "Outgoing Message from Connekt",
            date: "2022-09-14 14:37",
            channel: "Channel: Generic",
            body: "Dear Uzoma, It came to our attention that you were not present at the office yesterday...",
            receiver: "234810790957",
            pages: 2,
            status: "Sent"
        },
        {
            description: "Outgoing Message from Connekt",
            date: "2022-09-14 14:37",
            channel: "Channel: Generic",
            body: "Dear Uzoma, It came to our attention that you were not present at the office yesterday...",
            receiver: "2348097887591",
            pages: 2,
            status: "Sent"
        },
        {
            description: "Outgoing Message from Connekt",
            date: "2022-09-14 14:37",
            channel: "Channel: Generic",
            body: "Dear Uzoma, It came to our attention that you were not present at the office yesterday...",
            receiver: "2348097887591",
            pages: 2,
            status: "Failed"
        },
        {
            description: "Outgoing Message from Connekt",
            date: "2022-09-14 14:37",
            channel: "Channel: Generic",
            body: "Dear Uzoma, It came to our attention that you were not present at the office yesterday...",
            receiver: "2348097887591",
            pages: 2,
            status: "Failed"
        },
        {
            description: "Outgoing Message from Connekt",
            date: "2022-09-14 14:37",
            channel: "Channel: Generic",
            body: "Dear Uzoma, It came to our attention that you were not present at the office yesterday...",
            receiver: "2348097887591",
            pages: 2,
            status: "Failed"
        },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3;
    const totalPages = Math.ceil(messages.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentMessages = messages.slice(startIndex, startIndex + itemsPerPage);

    return (
        <div className="messaging-report">
            <Helmet>
                <title>Messaging Insight</title>
                <meta name="description" content="View all your messaging insights, including details of all sent messages." />
                <meta name="keywords" content="messaging, insights, sent messages, analytics" />
            </Helmet>
            
            <div className="invoice-container">
                <table className='invoice-table'>
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Receiver</th>
                            <th>Pages</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentMessages.map((message, index) => (
                            <tr key={index}>
                                <td>
                                    {message.description}<br />
                                    <span className="sub-text">{message.date} {message.channel}</span><br />
                                    <span className="sub-text">{message.body}</span>
                                </td>
                                <td>{message.receiver}</td>
                                <td>{message.pages}</td>
                                <td>
                                    <span className={`status ${message.status.toLowerCase()}`}>{message.status}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                startIndex={startIndex}
                itemsPerPage={itemsPerPage}
                totalItems={messages.length}
            />
        </div>
    );
};

export default MessagingReport;
