import React from 'react';
import { Helmet } from 'react-helmet';

const Developers = () => {
    return (
        <div>
            <Helmet>
                <title>Developers</title>
            </Helmet>
            <h1>Developers</h1>
            <p>Developer resources and documentation.</p>
        </div>
    );
};

export default Developers;
