import './Navbar.css';
import React from 'react';

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark justify-content-end">

        <div className="row custom-search-input">
            <input
              type="text"
              id="fromNumber"
              className="form-control mb-0"
              placeholder="Search"
            />
          </div>

            {/* <button className="btn btn-primary new-message-btn" type="button">New Message</button> */}
            <img src="../assets/bookmark.svg" alt="Profile" className="notification-icon" />
            <img src="../assets/notification.svg" alt="Profile" className="notification-icon" />
            <img src="../assets/share-alt.svg" alt="Profile" className="notification-icon" />
            <img src="../assets/person.svg" alt="Profile" className="notification-icon" />
            
            {/* <div className="profile-section">
                <img src="../assets/Emoji.png" alt="Profile" className="profile-icon" />
                <div className="dropdown">
                    <button className="btn profile-btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Username
                        <i className="fa-solid fa-angle-down toggle-icon-padding"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item" href="#profile">Profile</a>
                        <a className="dropdown-item" href="#settings">Settings</a>
                        <a className="dropdown-item" href="#logout">Logout</a>
                    </div>
                </div>
            </div> */}
        </nav>
    );
};

export default Navbar;
