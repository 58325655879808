import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faMessage, faChain, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
    const location = useLocation();
    const isActive = (path) => location.pathname === path;

    const navigate = useNavigate();

    const initialDropdownState = JSON.parse(localStorage.getItem('dropdownOpen')) || {
        customers: false,
        omnichannel: false,
        switch: false,
        tokens: false,
        insight: false,
        developers: false,
        settings: false,
        sales: false,
    };

    const [dropdownOpen, setDropdownOpen] = useState(initialDropdownState);

    useEffect(() => {
        localStorage.setItem('dropdownOpen', JSON.stringify(dropdownOpen));
    }, [dropdownOpen]);

    const toggleDropdown = (section) => {
        console.log(section);
        setDropdownOpen((prevState) => {
            const newState = Object.keys(prevState).reduce((acc, key) => {
                acc[key] = key === section ? !prevState[key] : false;
                return acc;
            }, {});
            return newState;
        });
    };

     // Handle the back navigation on click of the faArrowLeft icon
     const handleGoBack = () => {
      const referrerUrl = localStorage.getItem('referrerUrl');
      if (referrerUrl) {
          // Redirect the user to the stored referrer URL
          window.location.href = referrerUrl;
      } else {
          // Fallback: go back in history if no referrer URL is stored
          navigate(-1);
          window.location.href = 'https://crm.nativetalkapp.com/admin';
      }
  };

    return (
        <div className="col-md-2 d-none d-md-block bg-light sidebar pt-1">

          <FontAwesomeIcon icon={faArrowLeft} className="m-3 fa-2x theme-fg" onClick={handleGoBack} />

            <div className="sidebar-sticky mt-3">
                <ul className="nav flex-column">

      {/* Quick Links */}

      {/* Dashboard */}
      <li className="nav-item">
        <a className="nav-link" href="https://crm.nativetalkapp.com/admin">
          <img className="menu-icon" src="https://crm.nativetalkapp.com/assets/images/sideNav/icn_dashboard.svg" alt="Dashboard" />
          <span>Dashboard</span>
        </a>
      </li>

      {/* Customers */}
      <li className="nav-item">
        <a className="nav-link" href="https://crm.nativetalkapp.com/admin/TECH4MATION_TEST/clients">
          <img className="menu-icon" src="https://crm.nativetalkapp.com/assets/images/sideNav/icn_customers.svg" alt="Customers" />
          <span>Customers</span>
        </a>
      </li>


      {/* Sales Dropdown */}
      <li className="nav-item">
        <div className="nav-link" onClick={() => toggleDropdown('sales')}>
          <img className="menu-icon" src="https://crm.nativetalkapp.com/assets/images/sideNav/icn_sales.svg" alt="Sales" />
          <span>Sales</span>
          <FontAwesomeIcon icon={dropdownOpen.sales ? faAngleUp : faAngleDown} className="ml-auto" />
        </div>
        {dropdownOpen.sales && (
          <ul className="nav flex-column ml-3">
            <li className="nav-item">
              <a className="nav-link" href="https://crm.nativetalkapp.com/admin/TECH4MATION_TEST/proposals">
                Proposals
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://crm.nativetalkapp.com/admin/TECH4MATION_TEST/estimates">
                Estimates
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://crm.nativetalkapp.com/admin/TECH4MATION_TEST/invoices">
                Invoices
              </a>
            </li>
            {/* Add more Sales links here */}
          </ul>
        )}
      </li>

      {/* Subscriptions */}
      <li className="nav-item">
        <a className="nav-link" href="https://crm.nativetalkapp.com/admin/TECH4MATION_TEST/subscriptions">
          <img className="menu-icon" src="https://crm.nativetalkapp.com/assets/images/sideNav/icn_subscriptions.svg" alt="Subscriptions" />
          <span>Subscriptions</span>
        </a>
      </li>

      {/* Expenses */}
      <li className="nav-item">
        <a className="nav-link" href="https://crm.nativetalkapp.com/admin/TECH4MATION_TEST/expenses">
          <img className="menu-icon" src="https://crm.nativetalkapp.com/assets/images/sideNav/icn_expenses.svg" alt="Expenses" />
          <span>Expenses</span>
        </a>
      </li>

      {/* Contracts */}
      <li className="nav-item">
        <a className="nav-link" href="https://crm.nativetalkapp.com/admin/TECH4MATION_TEST/contracts">
          <img className="menu-icon" src="https://crm.nativetalkapp.com/assets/images/sideNav/icn_contracts.svg" alt="Contracts" />
          <span>Contracts</span>
        </a>
      </li>

      {/* Projects */}
      <li className="nav-item">
        <a className="nav-link" href="https://crm.nativetalkapp.com/admin/TECH4MATION_TEST/projects">
          <img className="menu-icon" src="https://crm.nativetalkapp.com/assets/images/sideNav/icn_projects.svg" alt="Projects" />
          <span>Projects</span>
        </a>
      </li>

      {/* Tasks */}
      <li className="nav-item">
        <a className="nav-link" href="https://crm.nativetalkapp.com/admin/TECH4MATION_TEST/tasks">
          <img className="menu-icon" src="https://crm.nativetalkapp.com/assets/images/sideNav/icn_tasks.svg" alt="Tasks" />
          <span>Tasks</span>
        </a>
      </li>

      {/* <li className="nav-item">
        <Link
            className={`nav-link ${isActive('/messages') ? 'active' : ''}`}
            to="/messages"
        >
            <FontAwesomeIcon icon={faCode} className="mr-2" /> Omnichannel
        </Link>
      </li> */}

      <li className="nav-item">
          <div className="nav-link" onClick={() => toggleDropdown('customers')}>
              <FontAwesomeIcon icon={faMessage} className="mr-2" /> Omnichannel
              <FontAwesomeIcon icon={dropdownOpen.omnichannel ? faAngleUp : faAngleDown} className="ml-auto" />
          </div>
          {dropdownOpen.customers && (
              <ul className="nav flex-column ml-3">
                  <li className="nav-item">
                      <Link className={`nav-link ${isActive('/dashboard') ? 'active' : ''}`} to="/dashboard">
                          <FontAwesomeIcon icon={faChain} className="mr-2" /> Channels
                      </Link>
                  </li>
                  <li className="nav-item">
                      <Link className={`nav-link ${isActive('/messages') ? 'active' : ''}`} to="/messages">
                          <FontAwesomeIcon icon={faMessage} className="mr-2" /> Messages
                      </Link>
                  </li>
              </ul>
          )}
      </li>

      {/* Support */}
      <li className="nav-item">
        <a className="nav-link" href="https://crm.nativetalkapp.com/admin/TECH4MATION_TEST/tickets">
          <img className="menu-icon" src="https://crm.nativetalkapp.com/assets/images/sideNav/icn_support.svg" alt="Support" />
          <span>Support</span>
        </a>
      </li>

      {/* Leads */}
      <li className="nav-item">
        <a className="nav-link" href="https://crm.nativetalkapp.com/admin/TECH4MATION_TEST/leads">
          <img className="menu-icon" src="https://crm.nativetalkapp.com/assets/images/sideNav/icn_leads.svg" alt="Leads" />
          <span>Leads</span>
        </a>
      </li>

      {/* Estimate Request */}
      <li className="nav-item">
        <a className="nav-link" href="https://crm.nativetalkapp.com/admin/TECH4MATION_TEST/estimate_request">
          <img className="menu-icon" src="https://crm.nativetalkapp.com/assets/images/sideNav/icn_customers.svg" alt="Estimate Request" />
          <span>Estimate Request</span>
        </a>
      </li>

      {/* Knowledge Base */}
      <li className="nav-item">
        <a className="nav-link" href="https://crm.nativetalkapp.com/admin/TECH4MATION_TEST/knowledge_base">
          <img className="menu-icon" src="https://crm.nativetalkapp.com/assets/images/sideNav/icn_knowledge base.svg" alt="Knowledge Base" />
          <span>Knowledge Base</span>
        </a>
      </li>


                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
