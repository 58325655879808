import React from 'react';
import { Helmet } from 'react-helmet';

const DND = () => {
    return (
        <div>
            <Helmet>
                <title>Do Not Disturb (DND)</title>
                <meta name="description" content="Manage and view insights related to Do Not Disturb (DND) settings and statistics." />
                <meta name="keywords" content="Do Not Disturb, DND, insights, management" />
            </Helmet>
            <h3>Do Not Disturb (DND)</h3>
            <p>Manage and view insights related to DND.</p>
            {/* Add relevant content and components for DND */}
        </div>
    );
};

export default DND;
