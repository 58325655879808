import React from 'react';
import { Helmet } from 'react-helmet';

const Tokens = () => {
    return (
        <div>
            <Helmet>
                <title>Tokens | Your App Name</title>
                <meta name="description" content="Manage your tokens here." />
            </Helmet>
            <h1>Tokens</h1>
            <p>Manage your tokens here.</p>
        </div>
    );
};

export default Tokens;
