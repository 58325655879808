import React from 'react';
import { Helmet } from 'react-helmet';

const NumberAPI = () => {
    return (
        <div>
            <Helmet>
                <title>Number API</title>
                <meta name="description" content="Manage and view insights related to your Number API. Access various functionalities and statistics." />
                <meta name="keywords" content="Number API, insights, management, API statistics" />
            </Helmet>
            <h3>Number API</h3>
            <p>Manage and view insights related to your Number API.</p>
        </div>
    );
};

export default NumberAPI;
