import React, { useState } from 'react';
import './Contacts.css'; 
import Pagination from '../../components/Pagination/Pagination';

const Contacts = () => {
    const [contacts, setContacts] = useState([
        { id: 1, name: 'John Doe', phone: '0' },
        { id: 2, name: 'Jane Smith', phone: '0' },
        { id: 3, name: 'Alice Johnson', phone: '0' },
        { id: 4, name: 'Bob Brown', phone: '0' },
        { id: 5, name: 'Charlie Davis', phone: '0' },
        { id: 6, name: 'Eve Black', phone: '0' },
    ]);

    const [searchQuery, setSearchQuery] = useState('');
    const [newPhoneBookName, setNewPhoneBookName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    const filteredContacts = contacts.filter(contact =>
        contact.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredContacts.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentContacts = filteredContacts.slice(startIndex, startIndex + itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleAddPhoneBook = () => {
        if (newPhoneBookName.trim()) {
            const newContact = {
                id: contacts.length + 1,
                name: newPhoneBookName,
                phone: '08000000000', 
            };
            setContacts([...contacts, newContact]);
            setNewPhoneBookName('');
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleNewPhoneBookNameChange = (event) => {
        setNewPhoneBookName(event.target.value);
    };

    return (
        <div className="contacts">
           <div className='d-flex'>
                <div className="d-flex align-items-center mb-3">
                    <h2 className="mb-0 me-2">Your Phone Book</h2>
                    <button className="ms-4 import-button">Import using Excel</button>
                </div>

                <div className="actions">
                    <input
                        type="text"
                        placeholder="Create new phone book list"
                        value={newPhoneBookName}
                        onChange={handleNewPhoneBookNameChange}
                    />
                    <button className="add-button" onClick={handleAddPhoneBook}>Add</button>
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                </div>
           </div>
           <p>
           Add new phone lists, edit, delete and manage all your <br />
           uploaded customer phone contacts
           </p>
            <div className="invoice-container">
                <table className="invoice-table">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Phone Book</th>
                            <th>Total Contacts</th>
                            <th>
                                <img src="../../assets/filter.png" alt="Filter" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentContacts.map((contact, index) => (
                            <tr key={contact.id}>
                                <td className="sn-cell">{startIndex + index + 1}</td>
                                <td className="phone-book-cell">{contact.name}</td>
                                <td className="phone-number-cell">{contact.phone}</td>
                                <td>
                                    <button className="invoice-actions-button">⋮</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                startIndex={startIndex}
                itemsPerPage={itemsPerPage}
                totalItems={filteredContacts.length}
            />
        </div>
    );
};

export default Contacts;
