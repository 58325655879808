import React from 'react';
import { Helmet } from 'react-helmet';

const EmailNotification = () => {
    return (
        <div>
            <Helmet>
                <title>Email Notification</title>
            </Helmet>
            <h3>Email Notification</h3>
            <p>Email notification settings go here.</p>
        </div>
    );
};

export default EmailNotification;
