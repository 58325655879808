import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleLeft, faAngleRight, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import './Pagination.css';

const Pagination = ({ currentPage, totalPages, onPageChange, startIndex, itemsPerPage, totalItems }) => {
    const handlePageClick = (page) => {
        if (page > 0 && page <= totalPages) {
            onPageChange(page);
        }
    };

    return (
        <div className="pagination">
            <div className="pagination-info">
                {`Showing ${startIndex + 1} to ${Math.min(startIndex + itemsPerPage, totalItems)} of ${totalItems} items`}
            </div>
            <div className="pagination-controls">
                <button
                    className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
                    onClick={() => handlePageClick(1)}
                >
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                </button>
                <button
                    className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
                    onClick={() => handlePageClick(currentPage - 1)}
                >
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
                <span>{currentPage} of {totalPages}</span>
                <button
                    className={`pagination-button ${currentPage === totalPages ? 'disabled' : ''}`}
                    onClick={() => handlePageClick(currentPage + 1)}
                >
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
                <button
                    className={`pagination-button ${currentPage === totalPages ? 'disabled' : ''}`}
                    onClick={() => handlePageClick(totalPages)}
                >
                    <FontAwesomeIcon icon={faAngleDoubleRight} />
                </button>
            </div>
        </div>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    startIndex: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
};

export default Pagination;
