import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import './ApiToken.css';

const ApiToken = () => {
    const [apiKey, setApiKey] = useState('TLM4wSONwGc55jFIuURtcow1KTlU9btGGoOeAAdYSPbHtv6s46bQKfVw068l2g');
    const [secretKey, setSecretKey] = useState('tsk_gsdhf62f2fd176fd84f0128eagu');
    const [copiedKey, setCopiedKey] = useState('');

    useEffect(() => {
        const fetchTokenDetails = async () => {
            try {
                const response = await axios.get('https://your-api-endpoint.com/get-token-details/1', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer YOUR_AUTH_TOKEN`
                    }
                });
                setApiKey(response.data.apiKey);
                setSecretKey(response.data.secretKey);
            } catch (error) {
                console.error('Error fetching token details:', error);
            }
        };

        fetchTokenDetails();
    }, []);

    const handleRenew = async () => {
        try {
            const response = await axios.post('https://your-api-endpoint.com/generate-new-token', {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer YOUR_AUTH_TOKEN`
                }
            });
            setApiKey(response.data.newApiKey);
            setSecretKey(response.data.newSecretKey);
            console.log('API Key renewed!');
        } catch (error) {
            console.error('Error renewing API Key:', error);
            console.log('An error occurred while renewing the API Key');
        }
    };

    const handleCopy = (text, keyType) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopiedKey(keyType);
            setTimeout(() => setCopiedKey(''), 2000);
        });
    };

    return (
        <div className="api-token-container">
            <Helmet>
                <title>API Token Management</title>
            </Helmet>
            <div className="key-container">
                <div className="key-block">
                    <label>API Key</label>
                    <textarea readOnly value={apiKey} />
                    <button
                        className="copy-button"
                        onClick={() => handleCopy(apiKey, 'apiKey')}
                    >
                        {copiedKey === 'apiKey' ? 'Copied' : <i className="fa-sharp fa-regular fa-copy"></i>}
                    </button>
                </div>
                <div className="key-block">
                    <label>Secret Key</label>
                    <textarea readOnly value={secretKey} />
                    <button
                        className="copy-button"
                        onClick={() => handleCopy(secretKey, 'secretKey')}
                    >
                        {copiedKey === 'secretKey' ? 'Copied' : <i className="fa-sharp fa-regular fa-copy"></i>}
                    </button>
                </div>
            </div>
            <button className="renew-button" onClick={handleRenew}>Renew API Key</button>
        </div>
    );
};

export default ApiToken;