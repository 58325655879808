import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './Insight.css';

const Insight = () => {
    const location = useLocation();

    const isActive = (path) => {
        return location.pathname.includes(path);
    };

    const routeInfo = {
        '/insight/messaging-report': {
            header: 'Message Report',
            description: 'View all your message reports. Reports captured here include all sent, failed, and delivered messages.',
            buttons: [
              { text: 'View Insight', link: '/insight/view' },
              { text: 'Filter', link: '' },
              { text: 'Download report in excel', link: '' }
            ]
        },
        '/insight/number-api': {
            header: 'Number API',
            description: 'Manage and view insights related to your Number API.',
            buttons: []
        },
        '/insight/dnd': {
            header: 'Do Not Disturb (DND)',
            description: 'Manage and view insights related to DND.',
            buttons: []
        },
        '/insight/generic': {
            header: 'Generic Insights',
            description: 'View and manage generic insights for your application.',
            buttons: []
        },
        '/insight/view': {
            header: 'Messaging Insight',
            description: 'View all your messaging insights.Insights captured here include all sent messages.',
            buttons: []
        },
    };

    const currentPath = Object.keys(routeInfo).find((path) => location.pathname.includes(path)) || '/insight/messaging-report';
    const { header, description, buttons } = routeInfo[currentPath];

    const insightContentClass = currentPath === '/insight/messaging-report'
        ? 'insight-content--no-bg'
        : 'insight-content';

    return (
        <div className="insight-component">
            <div className="header-buttons-row d-flex align-items-center">
               <div className='d-flex align-items-center'>
                    <h2>{header}</h2>
                    {buttons && buttons.length > 0 && (
                        <Link to={buttons[0].link}>
                            <button className="primary-action-button">{buttons[0].text}</button>
                        </Link>
                    )}
                </div>

                {buttons && buttons.length > 1 && (
                    <div className="additional-buttons d-flex gap-10">
                        {buttons.slice(1).map((button, index) => (
                            <button
                                key={index}
                                className={`additional-action-button additional-action-button-${index + 1}`}
                            >
                                {button.text}
                            </button>
                        ))}
                    </div>
                )}
            </div>
            
            <p>{description}</p>

            <div className="d-flex justify-content-between align-items-center">
                <div className="insight-sidebar">
                    <ul>
                        <li className={isActive('messaging-report') ? 'active' : ''}>
                            <Link to="messaging-report">
                                All
                                <FontAwesomeIcon icon={faAngleRight} className="fa-angle-right" />
                            </Link>
                        </li>
                        <li className={isActive('number-api') ? 'active' : ''}>
                            <Link to="number-api">
                                Number API
                                <FontAwesomeIcon icon={faAngleRight} className="fa-angle-right" />
                            </Link>
                        </li>
                        <li className={isActive('dnd') ? 'active' : ''}>
                            <Link to="dnd">
                                Do Not Disturb (DND)
                                <FontAwesomeIcon icon={faAngleRight} className="fa-angle-right" />
                            </Link>
                        </li>
                        <li className={isActive('generic') ? 'active' : ''}>
                            <Link to="generic">
                                Generic Insights
                                <FontAwesomeIcon icon={faAngleRight} className="fa-angle-right" />
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className={insightContentClass}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Insight;
