import React, { useState } from 'react';
import './GroupSMS.css';

const GroupSMS = () => {
  const [selectedChannel, setSelectedChannel] = useState('');
  const [selectedSenderID, setSelectedSenderID] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [recipients, setRecipients] = useState('');
  const [campaignType, setCampaignType] = useState('');
  const [messageType, setMessageType] = useState('Plain');
  const [message, setMessage] = useState('');

  const maxCharacters = 160;
  const charactersRemaining = maxCharacters - message.length;

  const handleSend = () => {
    if (!selectedChannel || !selectedSenderID || !recipients || !message) {
      alert('Please fill in all required fields');
      return;
    }

    const formattedRecipients = recipients
      .split(',')
      .map((recipient) => countryCode + recipient.trim())
      .filter((recipient) => recipient);

    console.log('Sending SMS', {
      selectedChannel,
      selectedSenderID,
      countryCode,
      recipients: formattedRecipients,
      campaignType,
      messageType,
      message,
    });
  };

  const handleViewReport = () => {
    console.log('Viewing report');
  };

  const countryCodes = [
    { code: '+1', name: 'USA' },
    { code: '+234', name: 'Nigeria' },
    { code: '+44', name: 'UK' },
    { code: '+91', name: 'India' },
  ];

  return (
    <div className="group-sms-container">
      <div className="container">
        <h2 className="text-left">Group SMS</h2>

        <div className="row mb-">
          <div className="col-md-6">
            <label htmlFor="channel">Select Channel</label>
            <select
              id="channel"
              className="form-control"
              value={selectedChannel}
              onChange={(e) => setSelectedChannel(e.target.value)}
            >
              <option value="">Select SMS channel</option>
              <option value="channel1">Channel 1</option>
              <option value="channel2">Channel 2</option>
            </select>
          </div>

          <div className="col-md-6">
            <label htmlFor="senderID">Sender ID / Device ID</label>
            <select
              id="senderID"
              className="form-control"
              value={selectedSenderID}
              onChange={(e) => setSelectedSenderID(e.target.value)}
            >
              <option value="">Select Sender ID</option>
              <option value="device1">Device 1</option>
              <option value="device2">Device 2</option>
            </select>
            <p className="text-primary-custom mt-2">
              Can't find your ID?{' '}
              <a href="#" className="link-custom">
                Register yours here
              </a>
              . Process takes less than 24 hours.
            </p>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="countryCode">Recipients</label>
            <select
              id="countryCode"
              className="form-control"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
            >
              <option value="">Select country code</option>
              {countryCodes.map(({ code, name }) => (
                <option key={code} value={code}>
                  {code} ({name})
                </option>
              ))}
            </select>

            <label htmlFor="phonebook">Recipients</label>
            <select
              id="phonebook"
              className="form-control mt-2"
              value={recipients}
              onChange={(e) => setRecipients(e.target.value)}
            >
              <option value="">Phone book</option>
              <option value="group1">Group 1</option>
              <option value="group2">Group 2</option>
            </select>
            <p className="mt-2">(Ensure you have added contacts to your phonebook)</p>

            <label htmlFor="messageType" className="mt-3">Message Type</label>
            <select
              id="messageType"
              className="form-control"
              value={messageType}
              onChange={(e) => setMessageType(e.target.value)}
            >
              <option value="Plain">Plain</option>
              <option value="Unicode">Unicode</option>
            </select>
          </div>


          <div className="col-md-6">
            <label htmlFor="campaignType">Campaign Type</label>
            <select
              id="campaignType"
              className="form-control"
              value={campaignType}
              onChange={(e) => setCampaignType(e.target.value)}
            >
              <option value="">Select Campaign type</option>
              <option value="promotion">Promotion</option>
              <option value="notification">Notification</option>
              <option value="alert">Alert</option>
            </select>
         
            <textarea
              className="form-control mt-2"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
              rows="4"
              maxLength={maxCharacters}
            />
            <p className="text-right mt-1">
              {charactersRemaining} characters remaining ({Math.ceil(message.length / maxCharacters)} message)
            </p>
          </div>
        </div>

        <div className="button-group" style={{ margin: '10px' }}>
          <button
            onClick={handleSend}
            className="btn btn-primary-custom mr-3"
          >
            Send
          </button>
          <button
            onClick={handleViewReport}
            className="btn btn-secondary-custom"
          >
            View report
          </button>
        </div>
      </div>
    </div>
  );
};

export default GroupSMS;
