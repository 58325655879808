import React, { useState } from 'react';
import './AddContactForm.css';

const AddContactForm = () => {
  const [formData, setFormData] = useState({
    countryCode: 'Nigeria (+234)',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    email: '',
    company: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  return (
    <div className="container-add-contact">
      <h2 className="header-add-contact">Add New Contact</h2>
      <p className="subheader-add-contact">
        Add a new contact by inputting their full details <br />
        and ensure you select the right country code.
      </p>
      <form className="form-add-contact" onSubmit={handleSubmit}>
        <div className="row-add-contact">
          <div className="column-add-contact">
            <label className="label-add-contact">Country Code</label>
            <input
              type="text"
              name="countryCode"
              value={formData.countryCode}
              onChange={handleChange}
              
              className="input-add-contact"
            />
          </div>
          <div className="column-add-contact">
            <label className="label-add-contact">Phone Number</label>
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              className="input-add-contact"
              placeholder="+234xxx"
            />
          </div>
        </div>
        <div className="row-add-contact">
          <div className="column-add-contact">
            <label className="label-add-contact">First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="input-add-contact"
              placeholder="Coco"
            />
          </div>
          <div className="column-add-contact">
            <label className="label-add-contact">Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="input-add-contact"
              placeholder="Lee"
            />
          </div>
        </div>
        <div className="row-add-contact">
          <div className="column-add-contact">
            <label className="label-add-contact">Email Address</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="input-add-contact"
              placeholder="Coco@gmail.com"
            />
          </div>
          <div className="column-add-contact">
            <label className="label-add-contact">Company</label>
            <input
              type="text"
              name="company"
              value={formData.company}
              onChange={handleChange}
              className="input-add-contact"
              placeholder="Lee Essentials"
            />
          </div>
        </div>
        <button type="submit" className="button-add-contact">Add</button>
      </form>
    </div>
  );
};

export default AddContactForm;
