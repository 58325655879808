import React, { useState } from 'react';
import './ForgotPassword.css';
import { Helmet } from 'react-helmet';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <>
         <Helmet>
                <title>Forgot Password</title>
         </Helmet>
        <div className="forgot-password-container">
            <div className="forgot-password-left">
                <div className="forgot-password-form">
                    <h2 style={{textAlign:"left"}}>Forgot Password?</h2>
                    <p style={{textAlign:"left"}}>Enter your email address, and we'll send you a link to reset your password.</p>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="email">Email Address</label>
                            <input 
                                type="email" 
                                id="email" 
                                name="email" 
                                value={email} 
                                onChange={handleEmailChange} 
                                required 
                            />
                        </div>
                        <p style={{textAlign:"left"}}><a href="/sign-in">Back to log in</a></p>
                        <button type="submit" className="btn btn-primary mb-3">Continue</button>
                    </form>
                    <p>Don't have an account? <a href="/sign-up">Sign up</a></p>
                </div>
            </div>
            <div className="signin-right">
                <img src={'../assets/top-image.png'} alt="top-right-image" className="top-right-image" />
                <img src={'../assets/Group.png'} alt="Logo" className="logo" />
                <img src={'../assets/button-image.png'} alt="buttom-left-image" className="buttom-left-image" />
            </div>
        </div>
        </>
    );
};

export default ForgotPassword;
